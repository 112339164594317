import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[overflowPadding]',
  standalone:true
})
export class OverflowPaddingDirective {

  private resizeObserver!: ResizeObserver;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {

    this.resizeObserver = new ResizeObserver(() => {
      this.checkForOverflow();
    });


    this.resizeObserver.observe(this.el.nativeElement);

    this.renderer.setStyle(this.el.nativeElement, 'transition', 'padding 0.3s ease');
  }

  private checkForOverflow() {
    const element = this.el.nativeElement;

    const isOverflowing = element.scrollHeight > element.clientHeight;


    if (isOverflowing) {
      this.renderer.setStyle(element, 'padding-right', '16px');
    } else {
      this.renderer.setStyle(element, 'padding-right', '0');
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

}
