import {
  Component,
  OnInit,
  output,
  input,
  Signal,
  effect,
  signal,
} from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule } from '@angular/forms';
import { debounce, debounceTime, map, startWith } from 'rxjs';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import {TuiLabel, TuiTitle} from '@taiga-ui/core';
import {TuiSliderComponent} from '@taiga-ui/kit';
import {TuiTextfield} from '@taiga-ui/core';
import {TUI_DEFAULT_INPUT_COLORS, TuiInputColorModule,TuiInputNumberModule, tuiInputNumberOptionsProvider} from '@taiga-ui/legacy';
import { LegendIconComponent } from '../../../legend-icon/legend-icon.component';

interface polygonStyleDefinition {
  color?: string | null;
  visibility?: boolean | null;
  width?: number | null;
  perpendicularOffset?: number | null;
  opacity?: number | null;
}

@Component({
  selector: 'app-polygon-style-form',
  templateUrl: './polygon-style-form.component.html',
  styleUrls: ['./polygon-style-form.component.css'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    ColorPickerModule,
    TuiLabel,
    TuiSliderComponent,
    TuiTitle,
    TuiInputColorModule, TuiTextfield,
    TuiInputNumberModule,
    LegendIconComponent
  ],
})
export class PolygonStyleFormComponent implements OnInit {
  form!: FormGroup;
  styleChanged = output<any>();
  styleDefinition: Signal<polygonStyleDefinition> = input();

  protected readonly palette = TUI_DEFAULT_INPUT_COLORS;

  preview = signal({
    name: 'null',
    symbolizers: [],
    filter: null,
  })
  
  constructor() {
    this.form = new FormGroup({
      kind: new FormControl('Fill'),
      color: new FormControl('#0E1058'),
      visibility: new FormControl(true),
      fillOpacity: new FormControl(null),
      opacity: new FormControl(null),
      outlineOpacity: new FormControl(null),
      outlineColor: new FormControl(''),
      outlineWidth: new FormControl(''),
    });

    effect(() => {
      const styleDefinition = this.styleDefinition();

      Object.keys(styleDefinition).forEach((key) => {
        const control = this.form.controls[key] as FormControl;
        const value = styleDefinition[key];

        if (control == null) {
          return;
        }
        control.setValue(value, { emitEvent: true });
      });
    });

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((values: polygonStyleDefinition) => this.filterNullValues(values)),
        debounceTime(100)
      )
      .subscribe((data) => {
        this.styleChanged.emit(data);
        this.preview.update(preview => ({...preview, symbolizers: [data]}))
      });
  }

  private filterNullValues(
    values: polygonStyleDefinition
  ): Partial<polygonStyleDefinition> {
    const filteredValues: Partial<any> = {};
    (Object.keys(values) as (keyof polygonStyleDefinition)[]).forEach((key) => {
      if (values[key] !== null) {
        filteredValues[key] = values[key];
      }
    });
    return filteredValues;
  }

  ngOnInit() {}
}
