import { Injectable, effect, inject, signal } from '@angular/core';
import { Feature, MapBrowserEvent, View } from 'ol';
import {easeOut} from 'ol/easing'
import OlMap from 'ol/Map';
import { Attribution, ScaleLine } from 'ol/control';
import LayerGroup from 'ol/layer/Group';
import { BasemapsService } from './Basemaps.service';
import * as olProj from 'ol/proj';
import Wkt from 'ol/format/WKT';
import { transform } from 'ol/proj';
import { Select } from 'ol/interaction';
import { HttpClient } from '@angular/common/http';
import { DataCatalogueService } from '../features/data-catalogue/data-access/services/data-catalogue.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { PropertiesService } from '../features/property/data-access/properties.service';

import {
  BehaviorSubject,
  NEVER,
  Subject,
  combineLatest,
  delay,
  delayWhen,
  fromEvent,
  map,
  of,
  pairwise,
  startWith,
  switchMap,
} from 'rxjs';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';
import VectorTileLayer from 'ol/layer/VectorTile';
import { environment } from 'src/environments/environment';
import { MVT } from 'ol/format';
import VectorTileSource from 'ol/source/VectorTile';
import BaseLayer from 'ol/layer/Base';


@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly basemapsService: BasemapsService = inject(BasemapsService);
  private readonly http: HttpClient = inject(HttpClient);
  private readonly dataCatalogueService: DataCatalogueService =
    inject(DataCatalogueService);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);

  public isZooming = new BehaviorSubject(false);
  public popoutLayers = new BehaviorSubject<BaseLayer[]>([]);

  private readonly initialCenter = [146.6, -30.83];
  private readonly initialZoom = 4.8;
  public baseMapGroup = new LayerGroup({
    layers: [],
    name: 'Background Maps',
    fold: 'open',
  } as any);

  cadasterLayer = new VectorTileLayer({
    source: new VectorTileSource({
      format: new MVT({ featureClass: Feature, idProperty: 'cad_pid' }),
      url: environment.apiUrl + 'cadastre/{z}/{x}/{y}',
      maxZoom: 14,
      minZoom: 10,
    }),
    visible: false,
    minZoom: 10,
    style: new Style({fill: new Fill({
      color: 'rgba(255,255,255,0.01)'
    }),
  stroke: new Stroke({color: 'rgba(255,74,255,1)', width: 2})})
  });

  public readonly featureMap: Map<string, Feature> = new Map();
  interaction: Select;
  public pointerCoordinate = signal(null);

  activeProperty = toSignal(this.propertiesService.activeProperty, {initialValue: null});

  public zoomToFeatureEvent = new Subject<string>();

  selection: {} = {};

  map!: OlMap;
  selectionLayer: any;

  eventKeys = new Set<EventsKey>();

  constructor() {

    combineLatest([this.zoomToFeatureEvent.asObservable(), this.propertiesService.propertiesLoaded]).pipe(
      delayWhen(([id, ready]) =>
        ready ? of('').pipe(delay(200)) : NEVER
      )).subscribe(
        ([featureID, _]) => {
          this.zoomToFeatureByID(featureID);
        }
      );


    combineLatest([
      this.propertiesService.activeProperty.pipe(startWith(null)),
      this.propertiesService.propertiesLoaded
    ]).pipe(
      delayWhen(([_, ready]) =>
        ready ? of('').pipe(delay(200)) : NEVER
      ),
      pairwise()
    ).subscribe(([[prevActiveProperty, prevReady], [currActiveProperty, currReady]]) => {
      const prev = prevActiveProperty;
      const curr = currActiveProperty;

      if (prev != null) {
        let feature = this.featureMap.get(prev.propertyID);
        if (feature) {
          feature.setStyle(null);
        }
      }

      if (curr == null) {
        return;
      }

      let feature = this.featureMap.get(curr.propertyID);
      if (feature) {
        feature.setStyle(
          new Style({
            stroke: new Stroke({
              color: 'rgba(255,0,0,1)',
              width: 1.5,
            }),
            fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
          })
        );
      }
    });

    this.init();

    this.dataCatalogueService.removeLayer.subscribe((layer) => {
      if (!layer) return;

      this.map.getLayers().remove(layer);
    });
    this.dataCatalogueService.addLayer.subscribe((layer) => {
      if (!layer) return;

      let index = this.map
        .getLayers()
        .getArray()
        .indexOf(this.propertiesService.propertyGroup);

      this.map.getLayers().insertAt(index, layer);
    });

    this.propertiesService.clearEvent.subscribe(() => {
      this.featureMap.clear();
    });

    this.propertiesService.propertyLoadedEvent.subscribe((data) => {
      this.featureMap.set(data.id, data.feature);
    });

    this.cadasterLayer.set('title', 'Cadastre');
    this.cadasterLayer.set('poppedOut', true);
  }

  init() {
    this.map = new OlMap({
      layers: [
        this.baseMapGroup,
        this.cadasterLayer,
        this.propertiesService.propertyGroup,
      ],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.initialZoom,
        maxZoom: 23,
      }),
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ],
    });

    this.setupBaseMaps();

    this.map.on('rendercomplete', (e) => {
      this.map.updateSize();
    });

    this.map.on('pointermove', (event) => {
      if (event.dragging) {
        return;
      }

      let coord = event.coordinate;

      let transformedCoord = olProj.toLonLat(coord);

      this.pointerCoordinate.set(transformedCoord);
    });

    fromEvent(this.map.getView(), 'change:resolution').pipe(
      map(() => this.map.getView().getZoom()),
      startWith(this.map.getView().getZoom()),
      pairwise(),
      switchMap(([prevZoom, newZoom]) => {
        if (prevZoom !== newZoom) {
          this.isZooming.next(true)
          return fromEvent(this.map!, 'moveend').pipe(
            map(() => {
              this.isZooming.next(false)
              return newZoom;
            })
          );
        }
        return [];
      })
    ).subscribe();
  }

  public setupMapEvents() {
    const CLICK_KEY = this.map.on('click', (e) => {
      this.propertiesService.handleDoubleClick(e, this.map, (id) => {
        this.zoomToFeatureByID(id);
      });
    });

    this.eventKeys.add(CLICK_KEY);
  }

  public resetMapView() {
    this.map
      .getView()
      .setCenter(transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'));

    this.map.getView().setZoom(this.initialZoom);

    this.propertiesService.activeProperty.next(null);
  }
  public removeMapEvents(): void {
    this.eventKeys.forEach((key) => {
      unByKey(key);
    });
    this.eventKeys.clear();
  }

  public convertWktToFeature(wkt: string): Feature<any> {
    const format = new Wkt();

    return format.readFeature(wkt);
  }

  public convertFeatureToWKT(feature: Feature<any>) {
    const format = new Wkt();

    return format.writeFeature(feature);
  }

  async setupBaseMaps() {
    const layers = (await this.basemapsService.buildLayers()) as any;

    layers.forEach((layer) => {
      this.baseMapGroup.getLayers().push(layer);
    });
  }

  getMap(): OlMap {
    return this.map;
  }

  setTarget(element) {
    this.map.setTarget(element);
    this.map.updateSize();
  }

  updateSize() {
    this.map.updateSize();
  }

  zoomToActiveProperty() {
    const activeProperty = this.activeProperty();

    if (activeProperty == null) {
      return;
    }
    this.zoomToFeatureEvent.next(activeProperty.propertyID)
  }

  private zoomToFeatureByID(
    id: string,
    padding: [number, number, number, number] = [60, 620, 60, 20],
    options?: {duration: number, easing: any}
  ) {

    let feature = this.featureMap.get(id);
    this.map
      .getView()
      .fit(feature.getGeometry().getExtent(), { padding: padding, duration: 1500, easing: easeOut });
  }

  zoomToFeature(
    feature: Feature,
    padding: [number, number, number, number] = [60, 620, 60, 20]
  ) {
    this.map
      .getView()
      .fit(feature.getGeometry().getExtent(), { padding: padding });
  }

  hideLayersForPrint() {
    this.propertiesService.propertyClasses.setVisible(false);
  }

  unhideLayers() {
    this.propertiesService.propertyClasses.setVisible(true);
  }

  getMapExtent() {
    return this.map.getView().calculateExtent();
  }

  addLayerToPopout(layer:BaseLayer)
  {
    const currentValue = this.popoutLayers.value;

    this.popoutLayers.next([...currentValue, layer]);
  }
}
