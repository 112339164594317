import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  effect,
  inject,
  input,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import { BehaviorSubject, Subject, pairwise, startWith, tap } from 'rxjs';
import OlMap from 'ol/Map';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';
@Component({
  selector: 'map-base-maps-select',
  templateUrl: './basemaps-select.component.html',
  styleUrls: ['./basemaps-select.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    TooltipDirective,
  ],
})
export class BasemapsSelectComponent implements OnInit {
  BaseMapGroup = input<LayerGroup>();
  map = input.required<OlMap>();

  @Output() selectedBaseMapName = new EventEmitter();

  public layers;

  public selectedBaseMap = new FormControl();

  basemapGroupEffect = effect(
    () => {
      this.layers = this.BaseMapGroup().getLayersArray();

      let defaultLayer;

      defaultLayer = this.layers.find((layer) => layer.getVisible() == true);

      this.selectedBaseMap.setValue(defaultLayer);
    },
    { allowSignalWrites: true }
  );

  constructor() {
    this.selectedBaseMap.valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(([previousLayer, selectedLayer]) => {
        if (previousLayer) {
          previousLayer.setVisible(false);
        }
        selectedLayer.setVisible(true);
        this.selectedBaseMapName.emit(selectedLayer.get('title'));
        this.map().render();
      });
  }

  init() {}

  ngOnInit(): void {}
}
