
@if(featureLayers() != null)
{
  <tui-accordion style="padding-top:1rem">
    <tui-accordion-item>
      <span class="flex justify-center align-center">Property Layers Area Breakdown</span>
      <ng-template tuiAccordionItemContent>
        <div class="container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Area (ha)</th>
              </tr>
            </thead>
            <tbody>
              @for (layer of featureLayers().layers; track layer.layerID) {
                @if(layer.isLandUse)
                {
                  <tr>
                    <td>{{ layer.name }}</td>
                    <td>{{ layer.area }}</td>
                  </tr>
                }
              }

            </tbody>
          </table>
          <div class="area-stats-grid">
            <p>Cumulative Area:</p> <span>{{ overview().cumulativeArea }} (ha)</span>
            <p>Property Area:</p> <span>{{ overview().propertyArea }} (ha)</span>
            <p>% of Property:</p> <span>{{ overview().percentageOfProperty | number: '1.2-2' }}</span>
            <p>Balance:</p> <span>{{ overview().balance }} (ha)</span>
          </div>
        </div>
        </ng-template>
    </tui-accordion-item>
  </tui-accordion>

}

<div class="layer-panel-container">
  <div class="layer-panel-header">
    <button appTooltip="Create Layer" tooltipPosition="left" type="button" (click)="createLayer()" class="button button--blue button--action justify-self-end">
      <i class="fa fa-plus"></i>
    </button>
  </div>

    <tui-scrollbar>
      <div class="layer-panel-content" overflowPadding>
      <tui-accordion >
        @for (layer of featureLayers().layers; track layer.layerID) {
            <tui-accordion-item [open]="featureLayerService.currentlySelectedLayer()?.layerID == layer.layerID" class="no-content-padding" [noPadding]="true">
              <app-layer-control [selectable]="true" [formControl]="test222" [value]="layer" [layer]="layer.mapLayer()" [map]="mapService.map" >
                <ng-template layerControlPreview ><legend-icon stopPropagation (click)="test2(layer)" [rule]="layer.style.rules[0]"></legend-icon></ng-template>
                <ng-template layerControlActions>
                @if (layer.isEditing()) {
                <button
                  (click)="featureLayerService.saveLayerChanges(layer)"
                  class="button button--blue button--action"
                  appTooltip="Save Layer Geometry Changes"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-check"></i>
                </button>
                <button
                  (click)="featureLayerService.cancelLayerEdit(layer)"
                  class="button button--blue button--action"
                  appTooltip="Cancel Edit"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-xmark"></i>
                </button>
                } @else {
                <button
                  (click)="featureLayerService.editLayer(layer)"
                  class="button button--blue button--action"
                  appTooltip="Edit Layer Geometry"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-pencil"></i>
                </button>
                }
                <button
                  class="button button--blue button--action"
                  (click)="downloadFile(layer.layerID)"
                  appTooltip="Download Layer"
                  toolTipPosition="left"
                  stopPropagation
                >
                  <i class="fa fa-download"></i>
                </button>

                <button
                (click)="deleteLayer(layer)"
                class="button button--danger button--action"
                appTooltip="Delete Layer"
                toolTipPosition="left"
                stopPropagation
              >
                <i class="fa fa-trash"></i>
              </button></ng-template>
              </app-layer-control>
              <ng-template tuiAccordionItemContent>
                <tui-scrollbar>
                  <div style="max-height: 300px; padding-inline:var(--size-3)">
                    @for (feature of layer.features; track feature.featureID) {
                      <project-layer-feature-item
                      #featureItemRef
                      [selected]="selectedFeature() == feature.featureID || hoveredFeature() == feature.featureID"
                      [id]="'feature-item-'+feature.featureID"
                      (zoomTo)="onClick($event)"
                      (mouseOver)="onHover($event)"
                     (mouseLeave)="onMouseOut($event)"
                     (edit)="featureLayerService.editFeature($event)"
                     (delete)="onDeleteFeature(layer,$event, $event.name)"
                     (saveChanges)="featureLayerService.saveFeatureChanges(layer,$event); deactivateAllModifyComponents()"
                     (cancelEdit)="featureLayerService.cancelFeatureEdit($event); deactivateAllModifyComponents()"
                      [feature]="feature"
                       ></project-layer-feature-item>


                    }
                  </div>
                </tui-scrollbar>
            </ng-template>
            </tui-accordion-item>

          }

      </tui-accordion>
    </div>
    </tui-scrollbar>


</div>



<app-feature-layers-toolbar [show]="featureLayerService.currentlySelectedLayer() != null && featureLayerService.currentlyEditingLayer() == null && featureLayerService.currentEditTarget() == null">
  @for (definition of drawTypeDefinitions; track definition) {
    @if (featureLayerService.currentlySelectedLayer()?.kind == definition.layerKind) {
        <map-digitize [differenceTargetLayers]="projectLayers()" EskTool #digitize [definition]="definition" [intersectTargetFeatures]="[propertyBoundaryFeature()]" (drawend)="createFeature($event, featureLayerService.currentlySelectedLayer())"  [map]="mapService.map" >
          <map-snap [disabled]="!digitize.active()" [baseInteraction]="digitize.drawInteraction()" [map]="mapService.map" [snapLayers]="snapLayers()" [active]="digitize.active()"></map-snap>
        </map-digitize>
    }
  }
</app-feature-layers-toolbar>

@if(featureLayerService.currentlyEditingLayer())
{
  <app-feature-layers-toolbar show="true">
    <map-select (delete)="onSelectDelete(featureLayerService.currentlyEditingLayer(),$event)" EskTool [active]="true" [map]="mapService.map" [layers]="[featureLayerService.modifyLayer]"></map-select>
    <translate EskTool (translateEnd)="featuresChanged($event)" [map]="mapService.map" [layers]="[featureLayerService.modifyLayer]" [active]="false"></translate>

    <map-intersect #intersect [intersectingFeatures]="[propertyBoundaryFeature()]" (transformed)="featureChanged($event)">
      <map-modify EskTool #modify (modifyEnd)="intersect.targetFeature.set($event)" [map]="mapService.map" [source]="featureLayerService.modifySource">
        <map-snap [disabled]="!modify.active()" [baseInteraction]="modify.modifyInteraction()" [map]="mapService.map" [snapLayers]="snapLayers()" [active]="modify.active()"></map-snap>
      </map-modify>
    </map-intersect>

    <map-doughnut-modify  EskTool [map]="mapService.map" [layers]="[featureLayerService.modifyLayer]" (modified)="featuresChanged($event)"></map-doughnut-modify>
</app-feature-layers-toolbar>
}


<app-feature-layers-toolbar [show]="featureLayerService.currentEditTarget()">
  @if (featureLayerService.currentEditTarget()) {
    <translate EskTool (translateEnd)="featuresChanged($event)" [map]="mapService.map" [layers]="[featureLayerService.modifyLayer]"></translate>
    <map-modify [differenceTargetLayers]="projectLayers()" [intersectTargetFeatures]="[propertyBoundaryFeature()]" EskTool #modify (modifyEnd)="featureChanged($event)" [map]="mapService.map" [source]="featureLayerService.modifySource">
      <map-snap [disabled]="!modify.active()" [baseInteraction]="modify.modifyInteraction()" [map]="mapService.map" [snapLayers]="snapLayers()" [active]="modify.active()"></map-snap>
    </map-modify>
  }
</app-feature-layers-toolbar>
