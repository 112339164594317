import { CdkDrag } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  input,
  model,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'pdf-map-scale',
  templateUrl: './pdf-map-scale.component.html',
  styleUrls: ['./pdf-map-scale.component.css'],
  standalone: true,
  imports: [CommonModule, CdkDrag],
})
export class PdfMapScaleComponent {
  mapScale = input.required();
}
