import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, effect, input, model, output } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { feature } from '@turf/turf';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { Intersect, Intersects } from 'src/app/shared/helpers/transformations';

@Component({
  selector: 'map-intersect',
  templateUrl: './intersect.component.html',
  styleUrls: ['./intersect.component.css'],
  standalone:true,
  imports:[CommonModule, MatMenuModule, MatCheckboxModule, TooltipDirective]
})
export class IntersectComponent implements OnInit {

  active = model(false);

  disabled = input(false);

  targetFeature = model<Feature>();

  intersectingFeatures = input<Array<Feature>>();

  intersectingLayers = input<Array<VectorLayer<any>>>();

  details = computed(() => {

    const intersectingLayers = this.intersectingLayers();

    if(intersectingLayers == null)
      return [];


    const test = []

    intersectingLayers.forEach(layer => {

      const name = layer.get('title');

      if(name == null)
        {
          return;
        }

      test.push({layer: layer, name: name, intersect: false});
    });

    return test;
  });

  outputFeature = computed(() => {

    const targetFeature = this.targetFeature();
    const active = this.active();
    const intersectingFeatures = this.intersectingFeatures();
    const intersectingLayers = this.details();


    if(targetFeature == null || intersectingFeatures == null || this.disabled())
      {
        return undefined;
      }

      if(!active)
        {
          return targetFeature;
        }



    const outputFeature = targetFeature.clone();

    intersectingFeatures.forEach(feature => {

      if(feature == null || feature == undefined)
        {
          return;
        }

      const intersects = Intersects(outputFeature, feature)

      if(!intersects)
        {
          return;
        }

      const clippedFeature = Intersect(outputFeature, feature);

      outputFeature.setGeometry(clippedFeature.getGeometry());
    });

    intersectingLayers.filter(lyr => lyr.intersect).flatMap(lyr => lyr.layer.getSource().getFeatures()).forEach(feature => {

      const intersects = Intersects(outputFeature, feature)

      if(!intersects)
        {
          return;
        }

      const clippedFeature = Intersect(outputFeature, feature);

      outputFeature.setGeometry(clippedFeature.getGeometry());
    });

        return outputFeature;

      });

  transformed = output<Feature>();

  constructor() {

    effect(() => {
      const output = this.outputFeature();

      if(output == null)
        {
          return;
        }


        this.transformed.emit(output);
    })
  }

  ngOnInit() {
  }

}
