import { Injectable, inject } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { tag } from '@turf/turf';
import { BehaviorSubject, Observable, from, retry, retryWhen, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PdfPrintDefinition } from '../pdf/models/printDefinition';

@Injectable()
export class SignalRService {
  private itemsSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);

  isConnected: BehaviorSubject<boolean> = new BehaviorSubject(false);

  alerts$ = this.itemsSubject.asObservable();

  private readonly endpoint = environment.hubUrl;

  constructor() {}
  hubConnection: signalR.HubConnection;

  public stopConnection() {
    this.hubConnection?.stop();
    this.isConnected.next(false);
  }

  public startConnection(token?: string) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.endpoint, {
        accessTokenFactory: () => token,
      })
      .withAutomaticReconnect()
      .build();

    return from(this.hubConnection.start()).pipe(
      tap(() => {
        window.addEventListener('beforeunload', () => {
          this.hubConnection.stop();
        });
      }),
      retry({
        delay: (error, retryCount) => {

          return new Promise((res) => setTimeout(res, 2000));
        },
      })
    );
  }

  public joinRoom(jobID: string) {
    return from(this.hubConnection.invoke('JoinRoom', jobID));
  }

  public getPrintDetails(jobID: string) {
    return from(this.hubConnection.invoke<PdfPrintDefinition>('GetPDFDetailsAsync', jobID));
  }

  public onFileReady(): Observable<string> {
    return new Observable<string>((observer) => {
      this.hubConnection.on('FileReady', (jobID) => {
        observer.next(jobID);
      });
    });
  }
}
