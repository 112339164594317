import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, effect, HostBinding, model, OnInit, signal } from '@angular/core';
import { Coordinate } from 'ol/coordinate';


export interface ContextMenuAction {
  label: string,
  action: () => void;
}

@Component({
  selector: 'context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
  standalone:true,
  animations: [
    trigger('fadeInOut', [
      state('hidden', style({
        opacity: 0,
        display: 'none'
      })),
      state('visible', style({
        opacity: 1,
        display: 'block'
      })),
      transition('hidden => visible', [
        style({ display: 'block' }),
        animate('100ms ease-in')
      ]),
      transition('visible => hidden', [
        animate('100ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ],
  host:{
    '[@fadeInOut]': 'isOpen() ? "visible" : "hidden"',
    '[style.display]': 'isOpen() ? "block" : "none"',
    '[style.position]': '"absolute"',
    '[style.z-index]': '99',
    'class':'context-menu'
  },
  imports:[CommonModule]

})
export class ContextMenuComponent implements OnInit {

  @HostBinding('style.left.px') left = 0;
  @HostBinding('style.top.px') top = 0;

  protected actions = signal<ContextMenuAction[]>([]);

  isOpen = model(false);

  private options;

  position = model<Coordinate>([0,0])

  constructor(private cdr: ChangeDetectorRef) {

    effect(() => {
      const position = this.position();

      this.left = position[0];
      this.top = position[1];
    })
   }

  ngOnInit() {
  }

  close()
  {
    this.isOpen.set(false);
  }

  actionClicked(action: () => void): void;
  actionClicked(action: (data: any) => void): void;
  actionClicked(action: (() => void) | ((data: any) => void)): void {
    if (action.length === 0) {
      action(null);
    } else {
      action(this.options.data);
    }
    this.close();
  }

  open(coordinates?: Coordinate, actions?:ContextMenuAction[], options?: {data?: any})
  {
    console.log(actions)
    if(coordinates)
    {
      this.position.set(coordinates);
    }
    if(actions)
    {
      this.actions.set(actions);
    }
    if(options)
    {
      this.options = options;
    }

    this.isOpen.set(true)

  }

}
