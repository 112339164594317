import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  Renderer2,
  Signal,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ButtonModule } from 'primeng/button';
import { Observable, delay, filter, map } from 'rxjs';

import { InputTextModule } from 'primeng/inputtext';

import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule, Dropdown } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { DisplayDatePipe } from 'src/app/shared/pipes/display-date.pipe';
import { CalendarModule } from 'primeng/calendar';
import { MaterialModule } from 'src/app/material/material.module';
import { CommonModule } from '@angular/common';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { FileListComponent } from '../../ui/file-list/file-list.component';
import { PropertiesService } from '../../data-access/properties.service';
import { PropertyClassService } from 'src/app/@core/property-class/data-access/services/property-class.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { PropertyStageService } from 'src/app/@core/property-stage/data-access/services/property-stage.service';
import { PropertySuitabilityService } from 'src/app/@core/property-suitability/data-access/services/property-suitability.service';
import { PropertyStatusService } from 'src/app/@core/property-status/data-access/services/property-status.service';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { UsersService } from 'src/app/@admin/features/users/data-access/users.service';

export class UpdatePropertyEvent {
  update: boolean;
  model?: any;

  constructor(update, model = null) {
    this.update = update;
    this.model = model;
  }

  static update(model: any) {
    return new UpdatePropertyEvent(true, model);
  }

  static cancel() {
    return new UpdatePropertyEvent(false);
  }
}

@Component({
  selector: 'app-edit-property',
  templateUrl: './edit-property.component.html',
  styleUrls: ['./edit-property.component.css'],
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    CommonModule,
    FormsModule,
    CalendarModule,
    InputMaskModule,
    FileUploadModule,
    DisplayDatePipe,
    MaterialModule,
    FileListComponent,
    EditorModule,
  ],
  providers:[UsersService]
})
export class EditPropertyComponent {


  @Output() createEvent = new EventEmitter<UpdatePropertyEvent>();

  private readonly toastService: ToastService = inject(ToastService);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);
  private readonly propertyClassService: PropertyClassService =
    inject(PropertyClassService);
  private readonly propertyStageService: PropertyStageService =
    inject(PropertyStageService);
  private readonly propertySuitabilityService: PropertySuitabilityService =
    inject(PropertySuitabilityService);
  private readonly propertyStatusService: PropertyStatusService = inject(
    PropertyStatusService
  );

  private readonly usersService: UsersService = inject(
    UsersService
  );

  form: FormGroup;
  files: any[] = [];
  classes = toSignal(this.propertyClassService.getAll());
  statuses = toSignal(this.propertyStatusService.getAll());
  stages = toSignal(this.propertyStageService.getAll());
  suitabilities = toSignal(this.propertySuitabilityService.getAll());
  processLeaders = toSignal(this.usersService.GetProcessLeaders())
  uploadUrl: string;
  isDirty: Signal<any>;

  constructor(@Inject(DIALOG_DATA) private data) {
    this.form = new FormGroup({
      propertyID: new FormControl(null),

      projectName: new FormControl(null),
      propertyName: new FormControl(null),
      propertyAddress: new FormControl(null),
      propertyOwnerName: new FormControl(null),
      processLeaderID: new FormControl(null),
      sourceName: new FormControl(null),
      sourceEmail: new FormControl(null),
      sourcePhone: new FormControl(null),

      primaryClassID: new FormControl(null),
      secondaryClassID: new FormControl(null),
      stageID: new FormControl(null),
      statusID: new FormControl(null),
      suitabilityID: new FormControl(null),

      // <> doesn't works here
      // /^(\d+[.]\d+|\d+)$/)] matches a number with or without a decimal point
      price: new FormControl<number | null>(null),
      updatedAt: new FormControl({ disabled: true, readOnly: true }), // it doesn't seem setting disabled has any affect
      zone: new FormControl(null),
      areaHaCalculated: new FormControl({ disabled: true, readOnly: true }),
      areaHaDisplay: new FormControl(null),
      waterAllocation: new FormControl(null),
      totalEmissions: new FormControl(null),
      emissionAssessmentYear: new FormControl(null),
      environmentalCertification: new FormControl(null),

      notes: new FormControl(null),

      createdAt: new FormControl(null),
      dateModified: new FormControl(null),
      listingDate: new FormControl(null),
      mapLabel: new FormControl(null),
      propertyCadastralID: new FormControl(null),
      region: new FormControl(null),
      state: new FormControl(null),
    });

    this.form.patchValue(this.data.model);
    this.uploadUrl = data.uploadUrl;

    this.files = data.model.files.map((model) => {
      return {
        downloadUrl: this.propertiesService.getFileURL(
          data.model.propertyID,
          model.safeName
        ),
        ...model,
      };
    });

    this.isDirty = toSignal(
      this.form.valueChanges.pipe(map(() => this.form.dirty && this.form.valid))
    );
  }

  cancel() {
    this.createEvent.emit(UpdatePropertyEvent.cancel());
  }

  submit() {
    const model = {};

    const changedValues = Object.keys(this.form.controls).filter(
      (key) => this.form.controls[key].dirty
    );

    // just showing a generic error message and preventing submission for the moment
    if (!this.form.valid) {
      this.toastService.showError('One or more inputs are invalid!');
      return;
    }

    for (let key of changedValues) {
      model[key] = this.form.controls[key].value;
    }

    this.createEvent.emit(UpdatePropertyEvent.update(model));
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  onUpload($event: any) {
    let files: any[] = $event.originalEvent.body.map((model) => {
      return {
        downloadUrl: this.propertiesService.getFileURL(
          this.form.get('propertyID').value,
          model.safeName
        ),
        ...model,
      };
    });

    this.files.push(...files);
    this.toastService.showSuccess('File Uploaded Successfully!');
  }

  OnFileDelete(model: any) {
    this.propertiesService
      .deleteFile(this.form.get('propertyID').value, model)
      .subscribe(() => {
        const index = this.files.indexOf(model);
        if (index > -1) {
          this.files.splice(index, 1);
        }

        this.toastService.showSuccess('File Deleted Successfully!');
      });
  }
}
