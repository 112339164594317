import { trigger, state, style, transition, animate } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit, contentChildren, effect, input, model } from '@angular/core';
import { EskTool } from 'src/app/map/util/esk-tool-interface';
import { EskToolDirective } from 'src/app/shared/directives/EskTool.directive';

@Component({
  selector: 'app-feature-layers-toolbar',
  templateUrl: './feature-layers-toolbar.component.html',
  styleUrls: ['./feature-layers-toolbar.component.css'],
  standalone:true,
  imports:[CommonModule],
  animations: [
    trigger('toolbarAnimation', [
      transition(':enter', [
        style({ transform: 'translate(-50%, -100%)', opacity: 0 }),
        animate('300ms ease-in', style({ transform: 'translate(-50%, 0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translate(-50%, -100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class FeatureLayersToolbarComponent implements OnInit {


  tools = contentChildren(EskToolDirective)

  isVisible = input(false);

  show = model(false);

  constructor() {

  }

  ngOnInit() {
  }

}
