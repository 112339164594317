import { TuiRoot } from "@taiga-ui/core";
import { APP_INITIALIZER, NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NG_EVENT_PLUGINS } from "@taiga-ui/event-plugins";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapModule } from './map/map.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OnlineStatusModule } from 'ngx-online-status';
import { authInterceptorProviders } from './@core/interceptors/auth.interceptor';
import { PropertyStatusService } from './@core/property-status/data-access/services/property-status.service';
import { PropertyClassService } from './@core/property-class/data-access/services/property-class.service';
import { PropertySuitabilityService } from './@core/property-suitability/data-access/services/property-suitability.service';
import { PropertyStageService } from './@core/property-stage/data-access/services/property-stage.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AccountService } from './@core/Auth/account.service';
import { OverlayContainer } from "@angular/cdk/overlay";
import { CustomOverlayContainer } from "./@core/utils/CustomOverlayContainer";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        MapModule,
        SharedModule,
        OnlineStatusModule,
        TuiRoot
    ], providers: [authInterceptorProviders, PropertyStatusService, PropertyClassService, PropertySuitabilityService, PropertyStageService, provideHttpClient(withInterceptorsFromDi()), provideAnimationsAsync(),
            {
              provide: APP_INITIALIZER,
              useFactory: (accountService: AccountService) => () => Promise.resolve(),
              multi: true,
              deps: [AccountService],
            },
            NG_EVENT_PLUGINS,
            importProvidersFrom(NgxPermissionsModule.forRoot()), { provide: OverlayContainer, useClass: CustomOverlayContainer },] })
export class AppModule { }
