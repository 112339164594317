import {
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  WritableSignal,
  computed,
  effect,
  forwardRef,
  inject,
  input,
  model,
  output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { Draw, Snap } from 'ol/interaction';
import { Circle as CircleGeom } from 'ol/geom';
import { FeatureLayersService } from '../../data-access/feature-layers.service';
import { Feature, MapBrowserEvent, Map as olMap } from 'ol';
import VectorSource from 'ol/source/Vector';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { DrawEvent } from 'ol/interaction/Draw';
import { filter, map, switchMap } from 'rxjs';
import { createResult } from '../../ui/create-feature-dialog/create-feature-dialog.component';
import { MaterialModule } from 'src/app/material/material.module';
import { NgxColorsModule } from 'ngx-colors';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { Circle, Fill, Image, Stroke, Style } from 'ol/style';
import OlParser from 'geostyler-openlayers-parser';
import { Style as geoStyle } from 'geostyler-style';
import { MapService } from '../../../../../../data-access/map.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { CircleToPolygon, Difference, Intersect, Intersects } from 'src/app/shared/helpers/transformations';
import { CdkListbox, CdkOption } from '@angular/cdk/listbox';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { Kind } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { FeatureLayersToolbarComponent } from '../../ui/feature-layers-toolbar/feature-layers-toolbar.component';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { PropertiesService } from '../../../../data-access/properties.service';
import VectorLayer from 'ol/layer/Vector';
import { SnapComponent } from 'src/app/map/ui/snap/snap.component';
import { ActivatedRoute } from '@angular/router';
import { ESKTOOL_TOKEN, EskTool } from 'src/app/map/util/esk-tool-interface';
import { trigger, transition, style, animate } from '@angular/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Control } from 'ol/control';
import { click } from 'ol/events/condition';
import { unByKey } from 'ol/Observable';

@Component({
  selector: 'map-digitize',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    CdkListbox,
    CdkOption,
    TooltipDirective,
    FeatureLayersToolbarComponent,
    SnapComponent,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule
  ],
  templateUrl: './draw-features-overlay.component.html',
  styleUrl: './draw-features-overlay.component.css',
  providers: [
    { provide: ESKTOOL_TOKEN, useExisting: forwardRef(() => DrawFeaturesOverlayComponent) }
  ],
  animations: [ trigger('slide-in', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)', opacity: 0 }),
      animate('300ms ease-in', style({ transform: 'translateX(0)', opacity: 1 }))
    ]),
    transition(':leave', [
      animate('300ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
    ])
  ])
]
})
export class DrawFeaturesOverlayComponent implements OnInit, OnDestroy, EskTool {

  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  public readonly featureLayerService: FeatureLayersService =
  inject(FeatureLayersService);
public readonly mapService: MapService = inject(MapService);
public readonly propertiesService: PropertiesService = inject(PropertiesService);
private readonly dialogService: ModalService = inject(ModalService);
private document: Document = inject(ElementRef).nativeElement.ownerDocument;

  intersectTargetFeatures = input([]);
  disabled = input(false);
  definition = input.required<{ drawType: Kind; icon: string; layerKind: Kind }>();
  active = model(false);

  differenceTargetLayers = input<Array<VectorLayer<any>>>();

  differenceTargets:FormGroup = new FormGroup([]);

  _differenceTargetLayers = computed(() => {

    const intersectingLayers = this.differenceTargetLayers();

    if(intersectingLayers == null)
      return [];


    const test = []

    intersectingLayers.forEach(layer => {

      const name = layer.get('title') as string;

      if(name == null)
        {
          return;
        }

      test.push({layer: layer, name: name});

      this.differenceTargets.addControl(name, new FormControl(true));
    });

    return test;
  });



  map = input.required<olMap>();

  drawend = output<Feature>();



  private dblClickListener: any;




  intersect = signal(true);
  difference = signal(true);

  drawType = computed(() => this.definition().drawType as any);




  fillColor: WritableSignal<string> = signal('rgba(0, 0, 255, 0.1)');
  strokeColor: WritableSignal<string> = signal('rgba(0, 0, 255, 1)');

  selectedLayerState = signal(null);


  drawInteraction = computed(() => {

    const drawType = this.drawType();

    if(drawType == null)
      {
        return null;
      }

      const style = new Style({
        fill: new Fill({ color: this.fillColor() }),
        stroke: new Stroke({ color: this.strokeColor() }),
        image: new Circle({
          fill: new Fill({ color: this.strokeColor() }),
          radius: 5,
        }),
      });

      const drawInteraction = new Draw({
        type: this.drawType(),
        source: new VectorSource(),
        style: style,
        finishCondition: (event) => {
          const sketch = drawInteraction.getOverlay().getSource().getFeatures()[0];

          const isClosingClick = this.isFirstPointClick(event.coordinate, sketch);

          if (isClosingClick) {
            return true;
          }


          if (event.originalEvent.key === 'Enter') {
            drawInteraction.finishDrawing();
            return true;
          }

          return false;
        }
      });

      drawInteraction.on('drawend',this.drawFinished.bind(this));


      return drawInteraction;

  });

  private isFirstPointClick(currentCoordinate, sketchFeature): boolean {
    if (!sketchFeature) return false;

    const geometry = sketchFeature.getGeometry();

    if (geometry.getType() !== 'Polygon') return true;


    const coordinates = geometry.getCoordinates()[0];
    const firstCoordinate = coordinates[0];

    const tolerance = 10;
    const dx = currentCoordinate[0] - firstCoordinate[0];
    const dy = currentCoordinate[1] - firstCoordinate[1];

    return Math.sqrt(dx * dx + dy * dy) < tolerance;
  }



  constructor() {
    effect(() => {
      const active = this.active();
      const drawInteraction = this.drawInteraction();

      if (drawInteraction == null) return;

      if (active) {
        this.map().addInteraction(drawInteraction);

        this.addDoubleClickListener();
      } else {
        this.map().removeInteraction(drawInteraction);

        this.removeDoubleClickListener();
      }
    });
  }

  private addDoubleClickListener() {

    this.dblClickListener = this.map().on("dblclick", (event) => {this.handleDoubleClick(event)});
  }

  private removeDoubleClickListener() {
    if (this.dblClickListener) {
      unByKey(this.dblClickListener);
    }
  }

  private async handleDoubleClick(event: MapBrowserEvent<any>) {
    event.preventDefault();
    event.stopPropagation();
    this.dialogService.showConfirmation('Are you finished?').subscribe(result => {
      if(!result.val)
      {
        return;
      }
      this.drawInteraction()?.finishDrawing();
     })
  }

  undo()
  {
    this.drawInteraction()?.removeLastPoint();
  }

  private async drawFinished(event: DrawEvent)
  {
    this.active.set(false);

    const feature = event.feature;
    const shouldIntersect = this.intersect();
    const intersectTargets = this.intersectTargetFeatures().filter(target => target != null);
    const shouldDifference = this.difference();
    const differenceTargetLayers = this._differenceTargetLayers();


    if (feature.getGeometry() instanceof CircleGeom) {
      const circleGeom = CircleToPolygon(feature.getGeometry(), 100);
      feature.setGeometry(circleGeom);
    }

    if(shouldIntersect && intersectTargets.length > 0)
      {

        intersectTargets.forEach(intersectTarget => {

          if(!Intersects(feature, intersectTarget))
            return;

          const clippedFeature = Intersect(feature, intersectTarget);
          feature.setGeometry(clippedFeature.getGeometry());
        });

      }

      if(shouldDifference)
        {
          const differenceTargets = this.differenceTargets.getRawValue();

          differenceTargetLayers.filter(lyr => differenceTargets[lyr.name] == true).flatMap(lyr => lyr.layer.getSource().getFeatures()).forEach(target => {

            const intersects = Intersects(feature, target)

            if(!intersects)
              {
                return;
              }

            const clippedFeature = Difference(feature, target);

            feature.setGeometry(clippedFeature.getGeometry());
          });

        }

      this.drawend.emit(feature);

  }


  ngOnDestroy(): void {
    this.mapService.setupMapEvents();
    this.mapService.getMap().removeInteraction(this.drawInteraction());
    this.removeDoubleClickListener();
    document.removeEventListener('keydown',this.hotKeyUndoLastPoint.bind(this) )
  }

  ngOnInit(): void {
    this.mapService.removeMapEvents();

      document.addEventListener('keydown', this.hotKeyUndoLastPoint.bind(this));
  }

  hotKeyUndoLastPoint(event: KeyboardEvent)
  {
    if(event.ctrlKey && event.key === 'z')
      {
        event.stopPropagation();
        event.preventDefault();

        this.drawInteraction()?.removeLastPoint();
      }
  }


}
