import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  standalone:true,
  animations: [
    trigger('toolbarAnimation', [
      transition(':enter', [
        style({ transform: 'translate(-50%, -100%)', opacity: 0 }),
        animate('300ms ease-in', style({ transform: 'translate(-50%, 0)', opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translate(-50%, -100%)', opacity: 0 }))
      ])
    ])
  ],
  host: {
    '[@toolbarAnimation]': '',
  },
})
export class ToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
