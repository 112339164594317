import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, computed, inject, input, output } from '@angular/core';
import { Feature } from 'ol';
import { Style, Stroke } from 'ol/style';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { UserFeatureDTO } from '../../data-access/feature-layers.service';

@Component({
  selector: 'project-layer-feature-item',
  templateUrl: './project-layer-feature-item.component.html',
  styleUrls: ['./project-layer-feature-item.component.css'],
  standalone: true,
  imports: [CommonModule, TooltipDirective]
})
export class ProjectLayerFeatureItemComponent implements OnInit {

  public elementRef:ElementRef = inject(ElementRef);

  feature = input.required<UserFeatureDTO>();

  selected = input(false);

  details = computed (() => {
    const feature = this.feature();

    if(feature == null)
    {
      return '';
    }
    if('shape_area' in feature.attributes)
    {
      return `${feature.attributes['shape_area']} (ha)`
    }
    else if('shape_length' in feature.attributes)
    {
      return `${feature.attributes['shape_length']} (m)`
    }

    return '';
  })


  zoomTo = output<Feature>();
  mouseOver = output<Feature>();
  mouseLeave = output<Feature>();
  edit = output<UserFeatureDTO>();
  delete = output<UserFeatureDTO>();
  saveChanges = output<UserFeatureDTO>();
  cancelEdit = output<UserFeatureDTO>();

  constructor() { }

  ngOnInit() {
  }

  onEdit(userFeature: UserFeatureDTO, event:MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();

    this.edit.emit(userFeature);
  }

  onDelete(userFeature: UserFeatureDTO, event:MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();

    this.delete.emit(userFeature);
  }

  onSaveChanges(userFeature: UserFeatureDTO, event:MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();

    this.saveChanges.emit(userFeature);
  }

  onCancelEdit(userFeature: UserFeatureDTO, event:MouseEvent)
  {
    event.preventDefault();
    event.stopPropagation();

    this.cancelEdit.emit(userFeature)
  }

}
