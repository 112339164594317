import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapContainerComponent } from './map-container/map-container.component';
import { LayersComponent } from './features/layers/layers.component';
import { MapChildRoutes } from './util/map-pages.config';

const routes: Routes = [
  {path: '', component:MapContainerComponent, children:MapChildRoutes}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MapRoutingModule { }
