import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-property-overlay',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './select-property-overlay.component.html',
  styleUrl: './select-property-overlay.component.css'
})
export class SelectPropertyOverlayComponent {

@Input() data;

@Output() propertySelected = new EventEmitter();

  /**
   *
   */
  constructor(){

  }

 select(property)
 {
  this.propertySelected.emit(property);
 }
}
