<mat-expansion-panel expanded="true">
  <mat-expansion-panel-header>
    <mat-panel-title>General</mat-panel-title>
  </mat-expansion-panel-header>
  <form class="flex flex-column gap--2" [formGroup]="form">

    <tui-input-color
    class="b-form"
    [colors]="palette"
    formControlName="color"
>
 Color
</tui-input-color>

    <!-- <div class="wrapper">
      <mat-slide-toggle formControlName="visibility"
        >Visibility</mat-slide-toggle
      >
    </div> -->

    <tui-input-number [step]="1" formControlName="radius" >Radius</tui-input-number>

    <label class="align-center" style="width: 100%;" tuiLabel>
      <span tuiTitle>
        Opacity:
      </span>
      <input
    size="m"
    tuiSlider
    type="range"
    min="0"
    max="1"
    step="0.1"
    formControlName="opacity"
/>
  </label>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Fill Opacity</mat-label>
      <input matInput  min="0"
      max="1"
      step="0.1" formControlName="fillOpacity" type="number" />
    </mat-form-field> -->

<tui-select formControlName="wellKnownName">
  Select a Shape
  <tui-data-list-wrapper
    *tuiDataList
    size="l"
    [items]="options"
  >
    <ng-template #content let-option>
      {{ option }}
    </ng-template>
  </tui-data-list-wrapper>
</tui-select>


    <tui-input-color
    class="b-form"
    [colors]="palette"
    formControlName="strokeColor"
>
 Stroke Color
</tui-input-color>

    <tui-input-number [step]="1" formControlName="strokeWidth" >Stroke Width</tui-input-number>

    <tui-input-number [step]="1" formControlName="rotate" >Rotate</tui-input-number>

    <!-- <mat-form-field appearance="outline">
      <mat-label>Stroke Opacity</mat-label>
      <input matInput  min="0"
      max="1"
      step="0.1" formControlName="strokeOpacity" type="number" />
    </mat-form-field> -->
  </form>
</mat-expansion-panel>

<div class="preview-section">
  <h3>Preview</h3>
  <div class="preview-container">
    <legend-icon [rule]="preview()"></legend-icon>
  </div>
</div>
