<sidebar [routeConfig]="routeConfig" routePrefix="/map">
  <app-map-information
    [currentCoordinates]="mapService.pointerCoordinate()"
    [currentUTMZone]="projectionService.CurrentUTMZone()"
  ></app-map-information>
  <context-menu [(isOpen)]="showContextMenu"></context-menu>
  <geocode [map]="mapService.map"></geocode>

  <toolbar-container>
    <map-measure EskTool [map]="mapService.map"></map-measure>
    <button
      appTooltip="Reset Map View"
      (click)="mapService.resetMapView()"
      class="esk-tool"
    >
      <i class="fa fa-home"></i>
    </button>
    <app-pivot-measure EskTool></app-pivot-measure>
    <map-zoom-area [map]="mapService.map"></map-zoom-area>
  </toolbar-container>
  <div class="controls-test">
    <button (click)="updateShowLayers()" class="layer-toggle-btn">
      <i [ngClass]="!showLayers() ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
    </button>
    <tui-scrollbar>
    <div overflowPadding style="max-height: 500px;" [@slideToggle]="showLayers() ? 'open' : 'closed'">

      <tui-accordion [@listAnimation]="popoutLayers()">
        @for (layer of popoutLayers().slice().reverse(); track layer().title) {
          @switch (layer().type) {

         @case ('vector') {
              <tui-accordion-item class="no-arrow no-right-border" [showArrow]="false">
              <app-layer-control    [map]="mapService.getMap()" [layer]="layer().layer">
                <ng-template layerControlPreview >
                  <style-renderer
                  #renderer
                  [kind]="layer().kind"
                  [style]="layer().layer.getStyle()"
                  [featureProperties]="layer().featureProperties"
                ></style-renderer></ng-template>
                <ng-template layerControlHeaderActions>
                  @if (layer().poppedOut) {
                    <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
                  }
                </ng-template>
              </app-layer-control>

            </tui-accordion-item>
            } @case ('image') {
              <tui-accordion-item class="no-padding">
              <app-layer-control       [map]="mapService.getMap()" [layer]="layer().layer">
  <ng-template layerControlHeaderActions>
    @if (layer().poppedOut) {
      <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
    }
  </ng-template>
              </app-layer-control>

              <ng-template tuiAccordionItemContent><app-layer-legend [layer]="layer()" ></app-layer-legend></ng-template>

            </tui-accordion-item>

            }

            @default { }


          }
        }
      </tui-accordion>


    </div>

  </tui-scrollbar>

    <map-base-maps-select
    [BaseMapGroup]="mapService.baseMapGroup"
    [map]="mapService.map"
  ></map-base-maps-select>
  </div>

  <div #map (contextmenu)="onRightClick($event)" (click)="onMapClick()" class="map"></div>
  <app-bottom-sheet [sidebarService]="sidebarService"></app-bottom-sheet>
</sidebar>
