import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';

@Component({
  selector: 'toolbar-container',
  templateUrl: './toolbar-container.component.html',
  styleUrls: ['./toolbar-container.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class ToolbarContainerComponent implements OnInit {
  @HostBinding('class') hostClasses = 'esk-toolbar';

  constructor() {}

  ngOnInit() {}
}
