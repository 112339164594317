import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-title',
  templateUrl: './pdf-title.component.html',
  styleUrls: ['./pdf-title.component.css'],
  standalone: true,
})
export class PdfTitleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
