import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pdf-eoi',
  templateUrl: './pdf-eoi.component.html',
  styleUrls: ['./pdf-eoi.component.css'],
  standalone: true,
})
export class PdfEoiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
