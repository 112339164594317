import { Component, OnDestroy, inject, input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EditPropertyComponent } from '../../features/edit-property/edit-property.component';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertiesTableComponent } from '../../features/properties-table/properties-table.component';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css'],
  standalone: true,
  imports: [MaterialModule, SharedModule],
})
export class BottomSheetComponent implements OnDestroy {
  sidebarService = input.required<SidebarService>();

  constructor(private bottomSheet: MatBottomSheet) {}
  ngOnDestroy(): void {
    this.bottomSheet.dismiss();
  }

  openBottomSheet(): void {
    this.sidebarService().close();
    this.bottomSheet.open(PropertiesTableComponent, {
      hasBackdrop: false,
      panelClass: 'test',
      data: { sidebar: this.sidebarService() },
    });
  }
}
