import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import Wkt from 'ol/format/WKT';
import * as turf from '@turf/turf';
import OverlayOp from 'jsts/org/locationtech/jts/operation/overlay/OverlayOp';
import GeometryFactory  from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import RelateOp from 'jsts/org/locationtech/jts/operation/relate/RelateOp';
import IsValidOp from 'jsts/org/locationtech/jts/operation/valid/IsValidOp'
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp'
import GeoJSONReader from 'jsts/org/locationtech/jts/io/GeoJSONReader';

import GeoJSONWriter from 'jsts/org/locationtech/jts/io/GeoJSONWriter';

import { BBox, FeatureCollection, MultiLineString, polygon } from '@turf/turf';
import { Circle, Geometry, LineString, MultiPolygon, Polygon } from 'ol/geom';

import { getArea } from 'ol/sphere';

interface BufferOptions {
  units?: any;
  amount?: number;
  projection?: string;
}

/**
 * Given an array of features, this function will return a single feature that is the union of all the
 * features in the array
 * @param featureArray - Array<Feature<any>>
 * @returns The union of all the features in the feature array.
 */

export function Union(featureArray: Array<Feature<any>>): Feature<any> {
  if (featureArray.length === 0) {
    return null;
  }

  if (featureArray.length < 2) {
    return featureArray.pop();
  }

  const format = new GeoJSON();
  const convertedFeatures = [];
  let union;

  featureArray.forEach((feature) => {
    let convertedFeature = format.writeFeatureObject(feature);
    convertedFeatures.push(convertedFeature);
  });

  while (convertedFeatures.length != 0) {
    if (union != null) {
      let feature = convertedFeatures.pop();

      union = turf.union(union, feature);
    } else {
      let featureOne = convertedFeatures.pop();
      let featureTwo = convertedFeatures.pop();

      union = turf.union(featureOne, featureTwo);
    }
  }
  return format.readFeature(union);
}

/**
 * Convert a Feature to GeoJson
 * @param feature - The feature to convert to GeoJSON.
 * @returns The GeoJSON representation of the feature.
 */
export function ConvertFeatureToGeoJson(feature: Feature<any>): any {
  const format = new GeoJSON();

  return format.writeFeature(feature);
}

export function ConvertFeatureToWKT(feature: Feature<any>): any {
  const format = new Wkt();

  return format.writeFeature(feature);
}

/**
 * Convert a FeatureCollection to GeoJSON
 * @param features - The array of features to be converted to GeoJSON.
 * @returns The GeoJSON representation of the features.
 */
export function ConvertFeatureCollectionToGeoJson(
  features: Array<Feature<any>>
): string {
  const format = new GeoJSON();

  return format.writeFeatures(features);
}

export function CovertWKTFeature(wkt: string): Feature<any> {
  const format = new Wkt();

  return format.readFeature(wkt);
}

/**
 * Convert GeoJson to a Feature
 * @param {string} geojsonString - The GeoJSON string to convert to a feature.
 * @returns The GeoJSON is converted to a Feature.
 */
export function ConvertGeoJsonToFeature(
  geojsonString: string,
  projection?: string
) {
  const format = new GeoJSON();

  return format.readFeature(geojsonString, { featureProjection: projection });
}

/**
 * Convert a GeoJSON string to a GeoJSON feature collection
 * @param {string} geojsonString - The GeoJSON string to convert to a FeatureCollection.
 * @returns The GeoJSON is converted to a FeatureCollection.
 */
export function ConvertGeoJsonToFeatureCollection(geojsonString: string) {
  const format = new GeoJSON();

  return format.readFeatures(geojsonString, {
    featureProjection: 'EPSG:28355',
  });
}

/**
 * `Buffer` takes a GeoJSON feature and returns a GeoJSON feature with a buffer around it
 * @param feature - The GeoJSON feature to be buffered.
 * @param {BufferOptions} [options] - {
 * @returns A GeoJSON feature
 */
export function Buffer(feature, options?: BufferOptions) {
  let units = options?.units == null ? 'meters' : options.units;
  let amount = options?.amount == null ? 10 : options.amount;
  let projection =
    options?.projection == null ? 'EPSG:28355' : options.projection;

  const format = new GeoJSON();

  let convertedFeature = format.writeFeatureObject(feature, {
    featureProjection: projection,
  });

  let buffered = turf.buffer(convertedFeature, amount, { units: units });

  return format.readFeature(buffered, { featureProjection: projection });
}

/**
 * It takes two features and returns the difference between them.
 * @param featureOne - The first feature to be used in the difference.
 * @param featureTwo - The feature to subtract from featureOne.
 * @returns The difference of the two polygons.
 */
export function Difference(
  featureOne: Feature<any>,
  featureTwo: Feature<any>,
  options?
) {
  let projection =
    options?.projection == null ? 'EPSG:28355' : options.projection;

  const format = new GeoJSON({dataProjection: projection, featureProjection: projection});

  let featureOneGeoJson = JSON.parse(format.writeFeature(featureOne));
  let featureTwoGeoJson = JSON.parse(format.writeFeature(featureTwo));

  let reader = new GeoJSONReader();
  let writer = new GeoJSONWriter();

let featureOneGeom = reader.read(JSON.stringify(featureOneGeoJson.geometry));
let featureTwoGeom = reader.read(JSON.stringify(featureTwoGeoJson.geometry));


const isValidGeometry = (geom: Geometry): boolean => {
  const isValidOp = new IsValidOp(geom);
  return isValidOp.isValid();
};


const bufferGeometry = (geom: Geometry, amount: number): Geometry => {
  const bufferOp = new BufferOp(geom);
  return bufferOp.getResultGeometry(amount);
};


let isFeatureOneValid = isValidGeometry(featureOneGeom);
let isFeatureTwoValid = isValidGeometry(featureTwoGeom);


if (!isFeatureOneValid) {
  featureOneGeom = bufferGeometry(featureOneGeom, 0);
  isFeatureOneValid = isValidGeometry(featureOneGeom);

  if (!isFeatureOneValid) {
    console.log('Feature one is still invalid after buffering.');
    return null;
  }
}


if (!isFeatureTwoValid) {
  featureTwoGeom = bufferGeometry(featureTwoGeom, 0);
  isFeatureTwoValid = isValidGeometry(featureTwoGeom);

  if (!isFeatureTwoValid) {
    console.log('Feature two is still invalid after buffering.');
    return null;
  }
}


if (isFeatureOneValid && isFeatureTwoValid) {

  let target = featureTwoGeom;

  if (featureTwoGeom.getGeometryType() == 'MultiPolygon') {

    const multiPolygon = featureTwoGeom;
    const intersectingPolygons = [];

    for (let i = 0; i < multiPolygon.getNumGeometries(); i++) {
      const polygon = multiPolygon.getGeometryN(i);
      if (RelateOp.intersects(polygon, featureOneGeom)) {
        intersectingPolygons.push(polygon);
      }

    }

    const geometryFactory = new GeometryFactory();

    target = geometryFactory.createMultiPolygon(
      intersectingPolygons.map(p => p)
    );

  }




const difference = OverlayOp.difference(featureOneGeom, target);
let feature = writer.write(difference);

return format.readFeature(feature);

}

return null;
}

/**
 * It takes two features, converts them to GeoJSON, then uses JTS to intersect them, then converts the
 * result back to a feature.
 * @param featureOne - The first feature to intersect
 * @param featureTwo - The feature that you want to intersect with featureOne.
 * @param [options] - {
 * @returns A GeoJSON Feature
 */
export function Intersect(featureOne, featureTwo, options?) {
  let projection =
    options?.projection == null ? 'EPSG:28355' : options.projection;

  const format = new GeoJSON();

  let featureOneGeoJson = JSON.parse(format.writeFeature(featureOne));
  let featureTwoGeoJson = JSON.parse(format.writeFeature(featureTwo));

  let reader = new GeoJSONReader();
  let writer = new GeoJSONWriter();

  let featureOneGeom = reader.read(JSON.stringify(featureOneGeoJson.geometry));

  let featureTwoGeom = reader.read(JSON.stringify(featureTwoGeoJson.geometry));

  let intersects = RelateOp.intersects(featureOneGeom, featureTwoGeom);

  if (intersects === true) {
    try {
      let intersected = OverlayOp.intersection(featureOneGeom, featureTwoGeom);

      if (
        intersected._points != null &&
        intersected._points._coordinates.length == 0
      ) {
        return null;
      }

      let feature = writer.write(intersected);

      return format.readFeature(feature);
    } catch {
      return null;
    }
  } else {
    return null;
  }
}

export function Intersects(featureOne, featureTwo) {
  const format = new GeoJSON();

  let featureOneGeoJson = JSON.parse(format.writeFeature(featureOne));
  let featureTwoGeoJson = JSON.parse(format.writeFeature(featureTwo));

  let reader = new GeoJSONReader();
  let writer = new GeoJSONWriter();

  let featureOneGeom = reader.read(JSON.stringify(featureOneGeoJson.geometry));

  let featureTwoGeom = reader.read(JSON.stringify(featureTwoGeoJson.geometry));

  return RelateOp.intersects(featureOneGeom, featureTwoGeom);
}

/**
 * It takes a feature, clones it, and then sets the properties of the new feature to the properties of
 * the original feature.
 * @param originalFeature - The feature that is being edited.
 * @param newFeature - The new feature that was created by the user.
 * @returns A new feature with the same properties as the original feature, but with the geometry of
 * the new feature.
 */
export function CloneProperties(
  originalFeature: Feature<any>,
  newFeature: Feature<any>
): Feature<any> {
  if (originalFeature == null || newFeature == null) {
    return null;
  }
  let properties = originalFeature.getProperties();

  Object.keys(properties).forEach((key) => {
    if (key != 'geometry') {
      if (key == 'SHAPE.LEN' || key == 'shape_length') {
        let length = CalculateLength(newFeature);

        newFeature.set(key, length);
      }
      if (key == 'SHAPE.AREA' || key == 'shape_area') {
        let area = CalculateArea(newFeature);

        newFeature.set(key, area);
      } else {
        newFeature.set(key, properties[key]);
      }
    }
  });
  return newFeature;
}

/**
 * It takes a feature, converts it to GeoJSON, then calculates the length of the feature
 * @param feature - Feature<any> - The feature to calculate the length of.
 * @param [options] - { projection: string }
 * @returns The length of the line in meters.
 */
export function CalculateLength(
  feature: Feature<any>,
  options?: { projection: string }
) {
  let projection =
    options?.projection == null ? 'EPSG:28355' : options.projection;

  const format = new GeoJSON();

  let featureGeoJson = JSON.parse(
    format.writeFeature(feature, { featureProjection: projection })
  );

  let length = turf.length(featureGeoJson, {});

  return parseFloat((length * 1000).toFixed(1));
}

/**
 * It takes a feature, converts it to GeoJSON, then calculates the area of the feature in hectares.
 * @param feature - Feature<any> - The feature to calculate the area of.
 * @param [options] - { projection: string }
 * @returns The area of the polygon in hectares.
 */
export function CalculateArea(
  feature: Feature<any>,
  options?: { projection: string }
) {
  let projection =
    options?.projection == null ? 'EPSG:28355' : options.projection;

  if (
    feature.getGeometry().getCoordinates().length == 1 &&
    feature.getGeometry().getCoordinates()[0].length == 0
  ) {
    return 0;
  }

  //let area = turf.area(featureGeoJson);
  const area = getArea(feature.getGeometry(), { projection: projection });

  return parseFloat((area / 10000).toFixed(1));
}

/**
 * Given a feature, return a turf feature of the same type
 * @param feature - The feature to be converted to a turf feature.
 * @returns A turf feature.
 */
function GetTurf(feature: any): turf.helpers.Feature<any> {
  let featureType = feature.geometry.type;
  let coordinates = feature.geometry.coordinates;

  if (coordinates.length == 0) {
    return null;
  }

  switch (featureType) {
    case 'Polygon':
      return turf.polygon(coordinates);
    case 'MultiPolygon':
      return turf.multiPolygon(coordinates);
    case 'LineString':
      return turf.lineString(coordinates);
    case 'MultiLineString':
      return turf.multiLineString(coordinates);
    case 'Point':
      return turf.point(coordinates);
    default:
      return undefined;
  }
}

export function CircleToPolygon(circle, sides) {
  const center = circle.getCenter();
  const radius = circle.getRadius();
  const points = [];
  for (let i = 0; i < sides; i++) {
    const angle = (2 * Math.PI * i) / sides;
    const x = center[0] + radius * Math.cos(angle);
    const y = center[1] + radius * Math.sin(angle);
    points.push([x, y]);
  }
  points.push(points[0]);
  return new Polygon([points]);
}
