<div class="d-flex btn-bar">
  <button class="button button--blue" (click)="showDataCatalogue()">
    <span class="fa fa-layer-group button-icon"></span>
    Data Catalogue
  </button>
  <!-- <button (click)="addTiledLayer()"> add random</button> -->
</div>

<!-- <cdk-accordion class="dc-accordion">
  <div cdkDropList (cdkDropListDropped)="drop($event)" class="layers">
    @for (layerGroup of layersService.test.slice().reverse(); track
    layer().title) { @switch (layer().type) { @case ('group') {
    <app-layer-group
      cdkDrag
      [layerGroup]="layer()"
      [map]="mapService.getMap()"
    ></app-layer-group>
    } @case ('vector') {
    <app-vector-layer cdkDrag [layer]="layer()"></app-vector-layer>
    } @case ('image') {
    <app-image-layer (popout)="mapService.addLayerToPopout(layer())" cdkDrag [layer]="layer()"></app-image-layer>
    } @default { } } }
  </div>
</cdk-accordion> -->

<tui-accordion>

    @for (layer of layersService.test.slice().reverse(); track
    layer().title) {


        @switch (layer().type) {

          @case ('group') {
            <tui-accordion-item class="no-padding" >
            <app-layer-control       [map]="mapService.getMap()" [layer]="layer().layer">

            </app-layer-control>
            <ng-template tuiAccordionItemContent>
            <app-layer-group-control [map]="mapService.getMap()" [layerGroup]="layer()" >

            </app-layer-group-control>
          </ng-template>
          </tui-accordion-item>
          } @case ('vector') {
            <tui-accordion-item class="no-arrow no-right-border" [showArrow]="false">
            <app-layer-control    [map]="mapService.getMap()" [layer]="layer().layer">
              <ng-template layerControlPreview >
                <style-renderer
                #renderer
                [kind]="layer().kind"
                [style]="layer().layer.getStyle()"
                [featureProperties]="layer().featureProperties"
              ></style-renderer></ng-template>
              <ng-template layerControlHeaderActions>
                @if (layer().poppedOut) {
                  <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
                }
                @else {
                  <button (click)="layersService.popout(layer)" class="button button--icon"><i class="fa fa-arrow-right-from-bracket"></i></button>
                }

              </ng-template>
            </app-layer-control>

          </tui-accordion-item>
          } @case ('image') {
            <tui-accordion-item class="no-padding">
            <app-layer-control       [map]="mapService.getMap()" [layer]="layer().layer">
<ng-template layerControlHeaderActions>
  @if (layer().poppedOut) {
    <button (click)="layersService.removePoppedOutLayer(layer)" class="button button--icon"><i class="fa fa-x"></i></button>
  }
  @else {
    <button (click)="layersService.popout(layer)" class="button button--icon"><i class="fa fa-arrow-right-from-bracket"></i></button>
  }

</ng-template>
            </app-layer-control>

            <ng-template tuiAccordionItemContent><app-layer-legend [layer]="layer()" ></app-layer-legend></ng-template>

          </tui-accordion-item>

          }

          @default { }


        }



  }
</tui-accordion>
