import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface IEndpoint {
  getAll();
  get(id: string);
  delete(id: string);
  update(id: String, model: {});
}

export class BaseEndpointService {
  protected endpoint: string;
  protected readonly http: HttpClient = inject(HttpClient);

  constructor(options: { route: string }) {
    this.endpoint = environment.apiUrl + options.route;
  }

  setResourceID(selector, id) {
    this.endpoint = this.endpoint.includes(selector)
      ? this.endpoint.replace(selector, id)
      : this.endpoint;
  }
}
