<table class="property-table">
  <tr>
    <td>Property Name</td>
    <td>{{ propertyDetails().name }}</td>
  </tr>
  <tr>
    <td>Class/Subclass</td>
    <td>{{ propertyDetails().class }}</td>
  </tr>
  <tr>
    <td>Approximate Area</td>
    <td>{{ propertyDetails().area }}</td>
  </tr>
  <tr>
    <td>Location</td>
    <td>{{ propertyDetails().location }}</td>
  </tr>
</table>
