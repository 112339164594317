import {
  Component,
  OnInit,
  computed,
  effect,
  inject,
  resolveForwardRef,
  signal,
  untracked,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { PropertiesService } from '../../data-access/properties.service';
import { combineLatest, EMPTY, filter, from, map, mergeMap, switchMap, tap } from 'rxjs';
import { FeatureLayersService } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { TuiLoader } from '@taiga-ui/core';
import {TuiScrollbar} from '@taiga-ui/core';
import {TuiTextfield} from '@taiga-ui/core';
import {TUI_DEFAULT_INPUT_COLORS, TuiInputColorModule,TuiInputNumberModule, tuiInputNumberOptionsProvider} from '@taiga-ui/legacy';
import { CreatePropertyViewComponent } from '../CreatePropertyView/CreatePropertyView.component';
import { PropertyViewService } from '../../data-access/PropertyViewService.service';
import {TuiDataList} from '@taiga-ui/core';
import {TuiDataListWrapper, tuiItemsHandlersProvider} from '@taiga-ui/kit';
import {TuiSelectModule, TuiTextfieldControllerModule} from '@taiga-ui/legacy';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { DataCatalogueService } from '../../../data-catalogue/data-access/services/data-catalogue.service';
import { PropertyViewDTO } from '../../data-access/models/property-view-model';

@Component({
  selector: 'app-property-details',
  standalone: true,
  imports: [CommonModule, RouterModule,TuiLoader,TuiTextfield,TuiInputColorModule,MatFormFieldModule,MatSelectModule,
    TuiSelectModule,
    TuiDataListWrapper,
    TuiDataList,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,],
  templateUrl: './property-details.component.html',
  styleUrl: './property-details.component.css',
  providers: [
    tuiItemsHandlersProvider({
        stringify: (item: PropertyViewDTO) => `${item.name}`,
    }),
],
})
export class PropertyDetailsComponent implements OnInit {
  private readonly route: ActivatedRoute = inject(ActivatedRoute);
  private readonly router: Router = inject(Router);
  private readonly propertiesService: PropertiesService =
    inject(PropertiesService);
  private readonly mapService: MapService = inject(MapService);
  private readonly featureLayerService: FeatureLayersService =
    inject(FeatureLayersService);
  private readonly catalogueService: DataCatalogueService = inject(DataCatalogueService);
  private readonly sidebarService: SidebarService = inject(SidebarService);

  private readonly modalService: ModalService = inject(ModalService);
  private readonly toastService: ToastService = inject(ToastService);
  private readonly propertyViewService: PropertyViewService = inject(PropertyViewService);
  private routeParams = toSignal(this.route.params, {});
  private propertyID  = computed<string>(() =>
    this.routeParams()['id']
      ? this.routeParams()['id'] == ':id'
        ? ''
        : this.routeParams()['id']
      : ''
  );

  protected readonly palette = TUI_DEFAULT_INPUT_COLORS;

  public viewControl = new FormControl<PropertyViewDTO>(null);

  public isZooming = toSignal(this.mapService.isZooming);

  views = toSignal(this.propertyViewService.propertyViews$);

  currentlyAddedLayers = toSignal(this.catalogueService.addedLayers$);

  public property = toSignal(
    this.propertiesService.activeProperty.asObservable()
  );

  public propertiesLoaded = toSignal(this.propertiesService.propertiesLoaded);

  public sidebarStatus = toSignal(this.sidebarService.opened);

  validID;

  constructor() {
    this.viewControl.valueChanges
      .pipe(
        // Only proceed if a valid view is selected
        tap(view => {
          if (!view) {
            return;
          }
          this.currentlyAddedLayers().forEach(catalogueLayer => catalogueLayer.layer.setVisible(false))
        }),
        switchMap(view =>
          from(view.layers).pipe(
            mergeMap(layer =>
              this.catalogueService.loadLayerByID(layer.dataCatalogueLayerID).pipe(
                tap(layerData => this.catalogueService.addLayerToMap(layerData,{visible: layer.isVisible, opacity: layer.opacity }))
              )
            )
          )
        )
      )
      .subscribe();



    this.validID = computed(() => (this.propertyID() == '' ? false : true));

    effect(() => {
      if (!this.validID()) {
        if (this.property()?.propertyID != null) {
          this.router.navigate([`/map/property`, this.property().propertyID]);
        }

        return;
      }

      this.propertiesService.setActivePropertyByID(this.propertyID()).subscribe()

        this.propertyViewService.getPropertyViews(this.propertyID()).subscribe();

      if (untracked(() => this.sidebarStatus())) {
        return;
      }

      this.sidebarService.open();
    }, {});

    effect(() => {
      const propertiesLoaded = this.propertiesLoaded();
      const propertyID = this.propertyID();

      if(propertiesLoaded && propertyID != null)
        this.mapService.zoomToFeatureEvent.next(propertyID)
    })
  }

  ngOnInit(): void {


  }

  onClick() {
    this.router.navigate([`/map/property`, this.propertyID(), 'digitize']);
  }

  zoomTo() {
    this.mapService.zoomToFeatureEvent.next(this.propertyID());
  }

  edit() {
    this.propertiesService
      .get(this.propertyID())
      .pipe(
        switchMap((property) => this.propertiesService.editProperty(property)),
        filter((result) => result.update),
        switchMap((result) =>
          this.propertiesService.update(this.propertyID(), result.model)
        ),
        switchMap(() => this.propertiesService.get(this.propertyID()))
      )
      .subscribe((property) => {});
  }

  delete() {
    this.modalService
      .showConfirmDelete('Property', this.property().propertyName)
      .pipe(
        filter((result) => result.ok),
        map(result => result.val),
        switchMap((result) =>
        {
          if(result == false)
            return EMPTY;

        return this.propertiesService.delete(this.propertyID())
        }
        )
      )
      .subscribe((data) => {
        this.toastService.showSuccess('Property Deleted');
        this.router.navigate(['/map/property', ':id']);
      });
  }

  createView()
  {
    const instance = this.modalService.showComponent(CreatePropertyViewComponent,{propertyID: this.propertyID()});


    instance.componentInstance.submit.subscribe(result => {

      instance.close();

      if(result.err)
      {
        return;
      }

      const model = result.unwrap();
      this.propertyViewService.createPropertyView(model).subscribe(response => console.log(response));
    });
  }

  export(propertyID) {
    this.modalService
      .showDownloadLayer()
      .pipe(
        switchMap((data) => {
          return this.propertiesService.exportSpatialData(propertyID, data.format);
        })
      )
      .subscribe((response: any) => {

        const contentType = response.headers.get('Content-Type') || 'application/octet-stream';

        const blob = new Blob([response.body], {
          type: contentType
        });

        const contentDisposition = response.headers.get('Content-Disposition');



        let filename = 'download.json';
        if (contentDisposition) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '').trim();
          }
        }


        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
  }

}
