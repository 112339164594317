<div class="flex flex-row gap--1 align-center">
<button [ngClass]="{'esk-tool--active' : active()===true}" [appTooltip]="'Draw ' + definition().drawType" class="esk-tool" (click)="active.set(!active())"><i [className]="definition().icon"></i></button>
@if (active()) {
  <button @slide-in appTooltip="Undo Last Point" class="esk-tool" (click)="undo()"><i class="fa fa-undo"></i></button>
  <button @slide-in appTooltip="Intersect with Property Boundary" [ngClass]="{'esk-tool--active' : intersect()===true}" (click)="intersect.set(!intersect())" class="esk-tool"><i class="fa fa-object-group"></i></button>
  <span class="flex flex-row align-center tool-group" >
    <button appTooltip="Difference with Layers" [ngClass]="{'esk-tool--active': difference() === true}"  (click)="difference.set(!difference())" class="esk-tool">
      <i class="fa fa-cut"></i>
    </button>
    <button appTooltip="Intersect Targets" [matMenuTriggerFor]="menu" class="menu-button">
      <i class="fa fa-chevron-down chevron-icon"></i>
    </button>
  </span>
<mat-menu class="mat-menu" #menu="matMenu">
  <div class="flex flex-column">
    @for (item of _differenceTargetLayers(); track $index) {
      <mat-checkbox [formControl]="differenceTargets.get(item.name)" (click)="$event.stopPropagation()">{{item.name}}</mat-checkbox>
    }

  </div>
</mat-menu>

}

</div>
