<div class="feature-item" [ngClass]="{'feature-item--selected': selected()}"  >
  <div  class="feature-item-description" (click)="zoomTo.emit(feature().olFeature)" (mouseover)="mouseOver.emit(feature().olFeature)" (mouseout)="mouseLeave.emit(feature().olFeature)">
    <span>{{ feature().name }} - {{details()}}</span>
  </div>
    <div class="feature-actions">
      @if (feature().isEditing()) {
        <button
          (click)="onSaveChanges(feature(),$event)"
          class="button button--action button--green-fill"
          appTooltip="Save Geometry Changes"
          toolTipPosition="left"
        >
          <i class="fa fa-check"></i>
        </button>
        <button
          (click)="onCancelEdit(feature(),$event)"
          class="button button--action button--warn"
          appTooltip="Cancel Edit"
          toolTipPosition="left"
        >
          <i class="fa fa-xmark"></i>
        </button>

        } @else {
        <button
          (click)="onEdit(feature(),$event)"
          class="button button--action button--blue"
          appTooltip="Edit Geometry"
          toolTipPosition="left"
        >
          <i class="fa fa-pencil"></i>
        </button>
        }

        <button
        (click)="onDelete(feature(),$event)"
        class="button button--action button--danger"
        appTooltip="Delete Geometry"
        toolTipPosition="left"
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
</div>

