<header class="dialog-titlebar">
  <ng-container *ngTemplateOutlet="headerRef()"></ng-container>
  <button class="button button--icon" (click)="onClose()"><i class="fa fa-x"></i></button>
</header>
<tui-scrollbar>
<section overflowPadding class="dialog-content">

    <ng-container *ngTemplateOutlet="contentRef()"></ng-container>

</section>
</tui-scrollbar>
<footer class="dialog-footer">
  <ng-container *ngTemplateOutlet="footerRef()"></ng-container>
</footer>
