<section #sheet id="pdf-container" class="test sheet padding-5mm">
  <div #map class="map"></div>
  <div class="restricted">
    @if (titleDefinition().visible) {
    <pdf-draggable [position]="titleDefinition().position">
      <pdf-title>{{ titleDefinition().text }}</pdf-title>
    </pdf-draggable>
    } @if (mapScaleDefinition().visible) {
    <pdf-draggable [position]="mapScaleDefinition().position">
      <pdf-map-scale [mapScale]="mapScaleFormatted()"></pdf-map-scale>
    </pdf-draggable>
    } @if (propertyDetailsDefinition().visible) {
    <pdf-draggable [position]="propertyDetailsDefinition().position">
      <app-pdf-property-details [propertyDetails]="propertyDetails()">
      </app-pdf-property-details>
    </pdf-draggable>
    } @if(disclaimerDefinition().visible) {
    <pdf-draggable [position]="disclaimerDefinition().position">
      <pdf-disclaimer>{{ disclaimerDefinition().text }}</pdf-disclaimer>
    </pdf-draggable>
    } @if (eoiDefinition().visible) {
    <pdf-draggable [position]="eoiDefinition().position">
      <pdf-eoi>{{ eoiDefinition().text }}</pdf-eoi>
    </pdf-draggable>
    } @if (logoDefinition().visible) {
    <pdf-draggable [position]="logoDefinition().position">
      <img src="assets\laguna_bay_logo.png" alt="" />
    </pdf-draggable>

    }

    <span></span>
  </div>
</section>
