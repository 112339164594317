
<div class="flex flex-row gap-1 align-center">
  <ng-content>


  </ng-content>
  <span class="flex flex-row align-center" >
    <button appTooltip="Intersect with Targets" [ngClass]="{'esk-tool--active': active() === true}"  (click)="active.set(!active())" class="esk-tool">
      <i class="fa fa-object-group"></i>
    </button>
    <button appTooltip="Intersect Targets" [matMenuTriggerFor]="menu" class="menu-button">
      <i class="fa fa-chevron-down chevron-icon"></i>
    </button>
  </span>

</div>
<mat-menu class="mat-menu" #menu="matMenu">
  <div class="flex flex-column">
    @for (item of details(); track $index) {
      <mat-checkbox [checked]="item.intersect" (click)="$event.stopPropagation()">{{item.name}}</mat-checkbox>
    }
  </div>
</mat-menu>
