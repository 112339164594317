@if (!validID()) {

<p class="selectPropertyWarning">
  Please select a property by clicking on one on the map or by double clicking on one in the properties table.
</p>
} @else if(property()) {
<div class="property-details">



  <div class="header flex flex-column gap--md">
    <div class="flex align-center gap--2 flex-row justify-end">
      <button class="button button--icon" (click)="edit()" id="editButton"><i class="fa fa-pencil"></i></button>
      <button class="button button--icon"   (click)="delete()"><i class="fa fa-trash"></i></button>
    </div>
    <h1>{{ property().propertyName }}</h1>
    <p class="property-address">{{ property().propertyAddress }}</p>
    <span class="status-badge">{{ property().status }}</span>

    <div class="actions">
      <button (click)="onClick()">Property Layers</button>

      <button [disabled]="isZooming()" (click)="zoomTo()">
        @if (isZooming()) {
          <tui-loader size="xs" [inheritColor]="true"></tui-loader>
        }
        @else {
          <i class="fa fa-magnifying-glass"></i>
        }
        Zoom To</button>
      <button
      (click)="export(property().propertyID)"
    >
      <i class="fa fa-download"></i>
      Download
    </button>
      <a routerLink="/create-pdf/print"><i class="fa fa-print"></i> Print PDF</a>
    </div>
    <div class="grid grid--column gap--2 align-center">
        <tui-select
          [formControl]="viewControl"
        >
        Views
        <ng-template tuiDataList>
          <tui-data-list >
            @for (view of views(); track $index) {
              <button tuiOption  [value]="view">
                {{ view.name }}
              </button>
            }
          </tui-data-list>
        </ng-template>

        </tui-select>

      <button class="action-button" (click)="createView()">Create View</button>
    </div>
  </div>

  <table class="property-table">
    <tr>
      <th class="section-heading" colspan="2">
        <h2>
          <span class="fa fa-circle-info button-icon"></span>
          Basic Information
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <strong>Owner Name </strong>
      </td>
      <td>
        {{ property().propertyOwnerName }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Project Name </strong>
      </td>
      <td>
        {{ property().projectName }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Process Leader </strong>
      </td>
      <td>
        {{ property().processLeader }}
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h2>
          <span class="fa fa-location-dot button-icon"></span>
          Property Details
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <strong>Area (Ha)</strong>
      </td>
      <td>
        {{ property().areaHaDisplay }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Zone</strong>
      </td>
      <td>
        {{ property().zone }}
      </td>
    </tr>
    <tr>
      <th colspan="2">
        <h2>
          <span class="fa fa-dollar-sign button-icon"></span>
          Value
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <strong>Price</strong>
      </td>
      <td>
        {{ property().price }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Primary Class</strong>
      </td>
      <td>
        {{ property().primaryClass }}
      </td>
    </tr>
    @if (property().secondaryClass) {
    <tr>
      <td>
        <strong>Secondary Class</strong>
      </td>
      <td>
        {{ property().secondaryClass }}
      </td>
    </tr>
    }
    <tr>
      <th colspan="2">
        <h2>
          <span class="fa fa-sitemap button-icon"></span>
          Source Information
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <strong>Source Name</strong>
      </td>
      <td>
        {{ property().sourceName }}
      </td>
    </tr>
    <!-- <tr>
      <td>
        <strong>Source Email</strong>
      </td>
      <td>
        {{ property().sourceEmail }}
      </td>
    </tr> -->

    <tr>
      <th colspan="2">
        <h2>
          <span class="fa fa-chart-line button-icon"></span>
          Emissions
        </h2>
      </th>
    </tr>
    <tr>
      <td>
        <strong>Total Emissions</strong>
      </td>
      <td>
        {{ property().totalEmissions }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Environmental Certification</strong>
      </td>
      <td>
        {{ property().environmentalCertification }}
      </td>
    </tr>
    <tr>
      <td>
        <strong>Last Emissions Assessment</strong>
      </td>
      <td>
        {{ property().emissionAssessmentYear | date }}
      </td>
    </tr>

    <tr>
      <th colspan="2">
        <h2>
          <span class="fa fa-clock-rotate-left button-icon"></span>
          Historical Data
        </h2>
      </th>
    </tr>
    <!-- <tr>
      <td>
        <strong>Listing Date</strong>
      </td>
      <td>
        {{ property().listingDate | date }}
      </td>
    </tr> -->
    <tr>
      <td>
        <strong>Created At</strong>
      </td>
      <td>
        {{ property().createdAt | date }}
      </td>
    </tr>
  </table>

  <!-- <div class="section">
          <h2>Basic Information</h2>
          <p><strong>Property ID: </strong> <span >{{property().propertyID}}</span></p>
          <p><strong>Owner Name: </strong> <span>{{property().propertyOwnerName}}</span></p>
      </div>


      <div class="section">
          <h2>Property Details</h2>
          <p><strong>Area (Ha): </strong> <span >{{property().areaHaDisplay}}</span></p>
          <p><strong>Zone: </strong> <span >{{property().zone}}</span></p>

      </div>


      <div class="section">
          <h2>Geographical Information</h2>

          <p><strong>State: </strong> <span>{{property().state}}</span></p>
          <p><strong>Region: </strong> <span>{{property().region}}</span></p>
      </div>

      <div class="section">
          <p><strong>Price: </strong> <span>{{property().price}}</span></p>
          <p><strong>Primary Class: </strong> <span>{{property().primaryClass}}</span></p>
          @if (property().secondaryClass) {
            <p><strong>Secondary Class:</strong> <span>{{property().secondaryClass}}</span></p>
          }
      </div>

      <div class="section">

          <p><strong>Source Name: </strong> <span>{{property().sourceName}}</span></p>
          <p><strong>Source Email: </strong> <span>{{property().sourceEmail}}</span></p>

      </div>


      <div class="section" >
          <h2>Historical Data</h2>
          <p><strong>Listing Date: </strong> <span>{{property().listingDate | date}}</span></p>
          <p><strong>Created At: </strong> <span>{{property().createdAt| date}}</span></p>

      </div> -->
</div>

}
