

<div class="property-list">
  <span>Select a Property</span>
  <div class="property-items">
    @for (item of data; track $index) {
      <button class="property-item" (click)="select(item)">{{item.projectName ? item.projectName : item.propertyName}}</button>
    }
  </div>
</div>
