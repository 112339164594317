import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { Feature } from 'ol';
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';

import { Style, Fill, Stroke } from 'ol/style';
import { min } from 'rxjs';
import { MapService } from 'src/app/map/data-access/map.service';
import { Union } from 'src/app/shared/helpers/transformations';
import { CreatePropertyEvent } from '../digitize-property-overlay/digitize-property-overlay.component';
import { environment } from 'src/environments/environment';
import { Geometry } from 'ol/geom';
import { EventsKey } from 'ol/events';
import { unByKey } from 'ol/Observable';

@Component({
  selector: 'select-property-cadastre-overlay',
  templateUrl: './select-property-cadastre-overlay.component.html',
  styleUrls: ['./select-property-cadastre-overlay.component.css'],
  standalone:true
})
export class SelectPropertyCadastreOverlayComponent
  implements OnInit, OnDestroy
{
  @Output() createEvent = new EventEmitter<CreatePropertyEvent>();

  private readonly mapService: MapService = inject(MapService);

  private eventKey: EventsKey;

  private selectionLayer;

  selection: Map<any, Feature[]> = new Map();

  selectedFeatureStyle = new Style({
    fill: new Fill({
      color: 'rgba(242,247,250,0.01)',
    }),
    stroke: new Stroke({
      color: 'rgb(255,16,16)',
      width: 2,
    }),
  });

  constructor() {}

  ngOnDestroy(): void {
    this.mapService.cadasterLayer.setVisible(false);
    this.mapService.getMap().removeLayer(this.selectionLayer);
    this.mapService.setupMapEvents();

    unByKey(this.eventKey);
  }

  ngOnInit() {
    this.selectionLayer = new VectorTileLayer({
      renderMode: 'vector',
      source: this.mapService.cadasterLayer.getSource(),
      style: (feature) => {
        if (this.selection.has(feature.getId())) {
          return this.selectedFeatureStyle;
        }
        return null;
      },
    });

    this.mapService.removeMapEvents();

    this.eventKey = this.mapService.map.on('moveend', () => {
      let atRequiredZoom = this.calculateIfAtRequiredZoom(
        this.mapService.map.getView().getZoom(),
        this.mapService.cadasterLayer.getMinZoom()
      );

      if (!atRequiredZoom) {
        this.mapService.cadasterLayer.setVisible(false);
        this.mapService.map.removeLayer(this.selectionLayer);
      } else {
        this.mapService.cadasterLayer.setVisible(true);
        this.mapService.map.addLayer(this.selectionLayer);
      }
    });

    let atRequiredZoom = this.calculateIfAtRequiredZoom(
      this.mapService.map.getView().getZoom(),
      this.mapService.cadasterLayer.getMinZoom()
    );

    if (atRequiredZoom) {
      this.mapService.cadasterLayer.setVisible(true);
      this.mapService.map.addLayer(this.selectionLayer);
    }

    this.mapService.map.on(['click'], (event: any) => {
      this.mapService.cadasterLayer
        .getFeatures(event.pixel)
        .then((features) => {
          if (!features.length) {
            this.selectionLayer.changed();
            return;
          }
          const feature = features[0];
          if (!feature) {
            return;
          }
          const fid = feature.getId();

          let _features = this.mapService.cadasterLayer
            .getSource()
            .getFeaturesInExtent(
              this.mapService.getMapExtent()
            ) as Feature<Geometry>[];

          _features = _features.filter((ft) => ft.getId() == fid);

          this.selection.set(fid, _features);

          this.selectionLayer.changed();
        });
    });
  }

  private calculateIfAtRequiredZoom(currentZoom, minZoom) {
    if (currentZoom >= minZoom) {
      return true;
    } else {
      return false;
    }
  }

  finished() {
    let selectedFeatures: Array<Feature<any>[]> = Array.from(
      this.selection.values()
    );

    let mergedFeatures = selectedFeatures.map((features) => Union(features));

    let propertyBoundary = Union(mergedFeatures);

    this.createEvent.emit(CreatePropertyEvent.create(propertyBoundary));
  }
  undo() {
    let keys = Array.from(this.selection.keys());

    let last = keys.pop();

    this.selection.delete(last);
    this.selectionLayer.changed();
  }
  cancel() {
    this.createEvent.emit(CreatePropertyEvent.cancel());
  }
}
