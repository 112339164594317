import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { BaseEndpointService, IEndpoint } from '../../interfaces/IEndpoint';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ElementLayoutDetails,
  PDFPageLayout,
  PaperOrientation,
  PaperSize,
} from '../models/printDefinition';
import { Point } from '@angular/cdk/drag-drop';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertiesService } from 'src/app/map/features/property/data-access/properties.service';
import { SignalRService } from '../../services/signalR.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class PdfService extends BaseEndpointService {
  private readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly propertyService = inject(PropertiesService);
  private readonly signalRService = inject(SignalRService);
  private readonly router = inject(Router);

  public processing = new BehaviorSubject(false);

  public form = this.formBuilder.group({
    mapTitle: this.formBuilder.group({
      text: ['',Validators.required],
      visible: true,
    }),
    eoi: this.formBuilder.group({
      text: '',
      visible: true,
    }),
    disclaimer: this.formBuilder.group({
      text: '',
      visible: true,
    }),
    logo: this.formBuilder.group({
      visible: true,
    }),
    propertyDetails: this.formBuilder.group({
      visible: true,
    }),
  });

  layouts = toSignal(this.getLayouts(), { initialValue: [] });

  selectedLayout = signal(null);

  layoutEffect = effect(
    () => {
      if (this.layouts().length == 0) {
        return;
      }

      this.selectedLayout.set(this.layouts()[0]);
    },

    { allowSignalWrites: true }
  );

  pageLayout = computed(() => this.selectedLayout()?.pageLayout ?? null);
  paperDefinition = computed(
    () => this.selectedLayout()?.paperDefinition ?? null
  );

  pageLayoutEffect = effect(
    () => {
      const pageLayout = this.pageLayout();

      if (pageLayout == null) {
        return;
      }

      this.titleDefinition.set({
        position: { x: pageLayout.title.x, y: pageLayout.title.y },
        text: pageLayout.title.text,
        visible: true,
      });

      this.form
        .get('mapTitle')
        .get('text')
        .setValue(pageLayout.title.text, { emitEvent: false });

      this.disclaimerDefinition.set({
        position: { x: pageLayout.disclaimer.x, y: pageLayout.disclaimer.y },
        text: pageLayout.disclaimer.text,
        visible: true,
      });

      this.form
        .get('disclaimer')
        .get('text')
        .setValue(pageLayout.disclaimer.text, { emitEvent: false });

      this.eoiDefinition.set({
        position: { x: pageLayout.eoi.x, y: pageLayout.eoi.y },
        text: pageLayout.eoi.text,
        visible: true,
      });

      this.form
        .get('eoi')
        .get('text')
        .setValue(pageLayout.eoi.text, { emitEvent: false });

      this.mapScaleDefinition.set({
        position: { x: pageLayout.mapScale.x, y: pageLayout.mapScale.y },
        visible: true,
      });

      this.propertyDetailsDefinition.set({
        position: {
          x: pageLayout.propertyDetails.x,
          y: pageLayout.propertyDetails.y,
        },
        visible: true,
      });

      this.logoDefinition.set({
        position: {
          x: pageLayout.logo.x,
          y: pageLayout.logo.y,
        },
        visible: true,
      });
    },
    { allowSignalWrites: true }
  );

  paperDefinitionEffect = effect(
    () => {
      const paperDefinition = this.paperDefinition();

      if (paperDefinition == null) {
        return;
      }

      this.paperOrientation.set(paperDefinition.orientation);
      this.paperSize.set(paperDefinition.size);
    },
    { allowSignalWrites: true }
  );

  paperOrientation = signal<PaperOrientation>('Portrait');

  paperSize = signal<PaperSize>('A4');

  extent = signal(null);

  titleDefinition = signal<{
    position: { x: number; y: number };
    text?: string;
    visible: boolean;
  }>({
    position: { x: 0, y: 0 },
    text: 'Some random Text',
    visible: true,
  });

  eoiDefinition = signal({
    position: { x: 371.968, y: -0.359375 },
    text: 'Some Random Text',
    visible: true,
  });

  disclaimerDefinition = signal({
    position: { x: 371.968, y: -0.359375 },
    text: 'Some Random Text',
    visible: true,
  });

  mapScaleDefinition = signal({
    position: { x: 0, y: 220 },
    visible: true,
  });

  propertyDetailsDefinition = signal({
    position: { x: 0, y: 22 },
    visible: true,
  });

  logoDefinition = signal({
    position: { x: 0, y: 22 },
    visible: true,
  });

  selectedBaseMapName = signal('');

  property = toSignal(this.propertyService.activeProperty, {
    initialValue: {
      propertyName: '',
      primaryClass: '',
      areaCalculated: '',
      propertyAddress: '',
    },
  });

  propertyDetails = computed(() => {
    return {
      name: this.property()?.propertyName,
      class: this.property()?.primaryClass,
      area: this.property()?.areaHaCalculated,
      location: this.property()?.propertyAddress,
    };
  });

  pdfRequest = computed(() => {
    return {
      propertyID: this.property() == null ? null : this.property().propertyID,
      extent: this.extent(),
      selectedBaseMap: this.selectedBaseMapName(),
      pageLayout: {
        logo: {
          x: this.logoDefinition().position.x,
          y: this.logoDefinition().position.y,
          visible: this.logoDefinition().visible,
        },
        propertyDetails: {
          x: this.propertyDetailsDefinition().position.x,
          y: this.propertyDetailsDefinition().position.y,
          visible: this.propertyDetailsDefinition().visible,
        },
        mapScale: {
          x: this.mapScaleDefinition().position.x,
          y: this.mapScaleDefinition().position.y,
          visible: this.mapScaleDefinition().visible,
        },
        disclaimer: {
          x: this.disclaimerDefinition().position.x,
          y: this.disclaimerDefinition().position.y,
          text: this.disclaimerDefinition().text,
          visible: this.disclaimerDefinition().visible,
        },
        eoi: {
          x: this.eoiDefinition().position.x,
          y: this.eoiDefinition().position.y,
          text: this.eoiDefinition().text,
          visible: this.eoiDefinition().visible,
        },
        title: {
          x: this.titleDefinition().position.x,
          y: this.titleDefinition().position.y,
          text: this.titleDefinition().text,
          visible: this.titleDefinition().visible,
        },
      },
      paperDefinition: {
        size: this.paperSize(),
        orientation: this.paperOrientation(),
      },
    };
  });

  redirectEffect = effect(() => {
    if (this.property() == null || this.property().propertyID == null) {
      this.router.navigate(['map']);
    }
  });

  constructor() {
    super({ route: 'pdf' });

    this.form.get('mapTitle').valueChanges.subscribe((data) => {
      this.titleDefinition.update((val) => {
        return { ...val, text: data.text, visible: data.visible };
      });
    });

    this.form.get('eoi').valueChanges.subscribe((data) => {
      this.eoiDefinition.update((val) => {
        return { ...val, text: data.text, visible: data.visible };
      });
    });

    this.form.get('disclaimer').valueChanges.subscribe((data) => {
      this.disclaimerDefinition.update((val) => {
        return { ...val, text: data.text, visible: data.visible };
      });
    });

    this.form.get('logo').valueChanges.subscribe((data) => {
      this.logoDefinition.update((val) => {
        return { ...val, visible: data.visible };
      });
    });

    this.form.get('propertyDetails').valueChanges.subscribe((data) => {
      this.propertyDetailsDefinition.update((val) => {
        return { ...val, visible: data.visible };
      });
    });

    effect(() => {
      const property = this.property();

      if(property == null)
      {
        return; 
      }
      this.propertyService.hideAllFeaturesExcept(this.property().propertyID)

    })


  }

  getLayouts() {
    return this.http.get<Array<PDFPageLayout>>(`${this.endpoint}/layouts`);
  }

  createPrintRequest() {
    this.processing.next(true);

    return this.http
      .post<string>(`${this.endpoint}/generate`, this.pdfRequest())
      .pipe(
        switchMap((jobID) =>
          this.signalRService
            .startConnection()
            .pipe(switchMap(() => this.signalRService.joinRoom(jobID)))
        ),
        switchMap(() => this.signalRService.onFileReady()),
        switchMap((jobId) =>
          this.http.get(`${this.endpoint}/${jobId}`, {
            observe: 'response',
            responseType: 'blob',
          })
        )
      )
      .subscribe((response) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename =
          this.getFilenameFromContentDisposition(contentDisposition);

        this.saveFile(response.body, filename);

        this.processing.next(false);
        this.signalRService.stopConnection();
      });
  }

  mapScale(map) {
    const INCHES_PER_UNIT = {
      m: 39.37,
      dd: 4374754,
    };
    const DOTS_PER_INCH = 72;

    const unit = map.getView().getProjection().getUnits();
    const resolution = map.getView().getResolution();
    const scale = Math.round(
      INCHES_PER_UNIT[unit] * DOTS_PER_INCH * resolution
    );

    return scale;
  }

  private getFilenameFromContentDisposition(
    contentDisposition: string | null
  ): string {
    if (!contentDisposition) return 'defaultFilename.txt';
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
      contentDisposition
    );
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return 'defaultFilename.txt';
    }
  }

  private saveFile(data: Blob, filename: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
}
