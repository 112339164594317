import { Component, EventEmitter, ViewContainerRef, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { SidebarService } from '../../../sidebar/data-access/sidebar.service';
import { filter, map, switchMap, tap } from 'rxjs';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { EditPropertyComponent } from '../edit-property/edit-property.component';
import { PropertiesService } from '../../data-access/properties.service';
import { MapService } from 'src/app/map/data-access/map.service';
import { Union } from 'src/app/shared/helpers/transformations';
import { Feature } from 'ol';
import { SelectPropertyCadastreOverlayComponent } from '../select-property-cadastre-overlay/select-property-cadastre-overlay.component';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import { ToastService } from 'src/app/@core/services/toast/toast.service';
import { CreatePropertyEvent, DigitizePropertyOverlayComponent } from '../digitize-property-overlay/digitize-property-overlay.component';
import { ToolbarComponent } from 'src/app/shared/components/toolbar/toolbar.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
@Component({
  selector: 'app-create-property',
  standalone: true,
  imports: [CommonModule, DigitizePropertyOverlayComponent,SelectPropertyCadastreOverlayComponent, DialogModule, ToolbarComponent, TooltipDirective],
  templateUrl: './create-property.component.html',
  styleUrl: './create-property.component.css'
})
export class CreatePropertyComponent {


  private readonly componentFactoryService: ComponentFactory = inject(ComponentFactory);

  private readonly sidebarService: SidebarService = inject(SidebarService);

  private readonly toastService: ToastService = inject(ToastService);

  private readonly propertiesService: PropertiesService = inject(PropertiesService)

  private readonly modalService: ModalService = inject(ModalService);


  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef)

activeToolbar = signal(null);

  createFromCadaster() {

    this.sidebarService.close();

    let component = this.componentFactoryService.appendComponentToBody(SelectPropertyCadastreOverlayComponent);


  }

  showToolbar(option:'digitize' | 'cadastre')
  {
    this.activeToolbar.set(option);
  }


   handleCreateEvent(event: CreatePropertyEvent)
  {
    this.modalService.showConfirmation(event.create ? "Are you sure you want to create this property?" : "Are you sure you want to cancel?").pipe(
      map(result => ({ result, event })),
  tap(({ result, event }) => {

    this.activeToolbar.set(null);

    if(result.err)
    {
      return;
    }
    if (!result.val && !event.create) {
      return;
    }
    else if (result.val && !event.create) {

      this.sidebarService.open();
      return;
    }
    else if(!result.val && event.create)
    {
      this.sidebarService.open();
      return;
    }

    this.sidebarService.open();
    this.createProperty(event.feature);

  }))
.subscribe();


  }



  createByDigitize()
  {
   let component = this.componentFactoryService.appendComponentToBody(DigitizePropertyOverlayComponent);

   this.sidebarService.close();

  }

  private createProperty(feature)
  {
    this.propertiesService.create(feature).pipe(tap(() => this.toastService.showSuccess("Property Created!")),
      switchMap(({propertyID, property}) => this.propertiesService.editProperty(property).pipe(map(result => ({propertyID, result})))),
      filter(({propertyID,result}) => result.update),
      switchMap(({propertyID,result}) => this.propertiesService.update(propertyID,result.model))
      ).subscribe();

  }

}
