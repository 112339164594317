import { CommonModule } from '@angular/common';
import { Component, OnInit, input, model } from '@angular/core';

@Component({
  selector: 'app-pdf-property-details',
  templateUrl: './pdf-property-details.component.html',
  styleUrls: ['./pdf-property-details.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class PdfPropertyDetailsComponent implements OnInit {
  propertyDetails = model.required<any>();
  constructor() {}

  ngOnInit() {}
}
