import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Signal,
  ViewChild,
  computed,
  effect,
  inject,
  input,
  signal,
  untracked,
  viewChild,
  viewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureLayersService, LayerDTO, UserFeatureDTO } from '../../data-access/feature-layers.service';
import { EMPTY, Observable, Subject, filter, map, of, switchMap } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MaterialModule } from 'src/app/material/material.module';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { CustomValidators } from 'src/app/shared/extensions/CustomValidators';
import { DrawFeaturesOverlayComponent } from '../draw-features-overlay/draw-features-overlay.component';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { CdkAccordionItem, CdkAccordionModule } from '@angular/cdk/accordion';
import { ModalService } from 'src/app/@core/services/modal/modal.service';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Kind, StyleRendererComponent } from 'src/app/shared/components/style-renderer/style-renderer.component';
import { StyleEditorComponent } from 'src/app/shared/components/style-renderer/style-editor/style-editor.component';
import { SelectPropertyOverlayComponent } from '../../../../ui/select-property-overlay/select-property-overlay.component';
import { ActivatedRoute, ActivationEnd } from '@angular/router';
import { FeatureLayersToolbarComponent } from '../../ui/feature-layers-toolbar/feature-layers-toolbar.component';
import { ComponentFactory } from 'src/app/@core/services/ComponentFactory/component-factory.service';
import { Select, Translate } from 'ol/interaction';
import { Collection, Feature } from 'ol';
import { TranslateComponent } from 'src/app/map/ui/translate/translate.component';
import { MapService } from 'src/app/map/data-access/map.service';
import { ModifyComponent } from 'src/app/map/ui/modify/modify.component';
import { EskToolDirective } from 'src/app/shared/directives/EskTool.directive';
import { PropertiesService } from '../../../../data-access/properties.service';
import { SnapComponent } from 'src/app/map/ui/snap/snap.component';
import { SelectComponent } from 'src/app/map/ui/select/select.component';
import { createResult } from '../../ui/create-feature-dialog/create-feature-dialog.component';
import { TooltipDirective } from 'src/app/shared/directives/tooltip/tooltip.directive';
import { LegendIconComponent } from 'src/app/shared/components/style-renderer/legend-icon/legend-icon.component';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { ProjectLayerFeatureItemComponent } from '../../ui/project-layer-feature-item/project-layer-feature-item.component';
import { IntersectComponent } from 'src/app/map/ui/intersect/intersect.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditProjectLayerComponent } from '../../ui/edit-project-layer/edit-project-layer.component';
import { LayerOpacityDirective } from 'src/app/map/features/layers/utils/layer-opacity.directive';
import { LayerControlComponent } from 'src/app/shared/features/layer-control/layer-control.component';
import { LayerControlPreviewDirective } from 'src/app/shared/features/layer-control/layer-control-preview.directive';
import { LayerControlActionsDirective } from 'src/app/shared/features/layer-control/layer-control-actions.directive';
import { TuiAccordion, TuiAccordionItem } from '@taiga-ui/kit';
import { StopPropagationDirective } from 'src/app/shared/directives/stop-propagation.directive';
import { LayerControlServiceService } from 'src/app/shared/features/layer-control/LayerControlService.service';
import { TuiScrollable, TuiScrollbar } from '@taiga-ui/core';
import { click, doubleClick, pointerMove, singleClick } from 'ol/events/condition';
import { featureEach } from '@turf/turf';
import { EskToolService } from 'src/app/shared/features/esk-tool/EskTool.service';
import { OverflowPaddingDirective } from 'src/app/shared/directives/overflow-padding.directive';

@Component({
  selector: 'app-feature-layers',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    DrawFeaturesOverlayComponent,
    CdkAccordionModule,
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    SelectPropertyOverlayComponent,
    CdkDropList,
    CdkDrag,
    StyleEditorComponent,
    StyleRendererComponent,
    FeatureLayersToolbarComponent,
    TranslateComponent,
    ModifyComponent,
    SnapComponent,
    EskToolDirective,
    SelectComponent,
    TooltipDirective,
    LegendIconComponent,
    ProjectLayerFeatureItemComponent,
    IntersectComponent,
    MatExpansionModule,
    LayerOpacityDirective,
    LayerControlComponent,
    LayerControlPreviewDirective,
    LayerControlActionsDirective,
    TuiAccordion,
    TuiAccordionItem,StopPropagationDirective,
    TuiScrollbar,
    OverflowPaddingDirective
  ],
  templateUrl: './feature-layers.component.html',
  styleUrl: './feature-layers.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers:[LayerControlServiceService]
})
export class FeatureLayersComponent implements OnInit, OnDestroy {
  public readonly featureLayerService: FeatureLayersService =
    inject(FeatureLayersService);

    modify = viewChildren(ModifyComponent);

    featureItems = viewChildren(ProjectLayerFeatureItemComponent);


    selectedFeature = signal(null);
    hoveredFeature = signal(null);

    selectedFeatureItem = computed(() => {
      const selectedFeature = this.selectedFeature();
      const featureItems = this.featureItems();
      const editingFeature = this.featureLayerService.currentEditTarget();


      if(selectedFeature == null && editingFeature == null)
      {
        return null;
      }

     const test =  featureItems.find(component => {
        return component.elementRef.nativeElement.id == `feature-item-${selectedFeature}`;
      })
      if(test)
        {
          return test.elementRef.nativeElement;
        }

      return null;
    })


    testEffect = effect(()=>
    {
        if(this.selectedFeatureItem())
        {
          this.selectedFeatureItem().scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    })

    public readonly propertiesService: PropertiesService =
    inject(PropertiesService);

    public readonly mapService: MapService =
    inject(MapService);
  private readonly modalService: ModalService = inject(ModalService);

  private readonly activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private readonly eskToolService: EskToolService = inject(EskToolService);

  private params = toSignal(this.activatedRoute.paramMap);

  public property = toSignal(this.propertiesService.activeProperty);

  private propertyID = computed(() => {
    const params = this.params();

    return params.get('id') ?? null;
  });

   propertyBoundaryFeature = computed(() => {
    const propertyID = this.propertyID();

   const feature = this.mapService.featureMap.get(propertyID);

   return feature;

  });


  public form: UntypedFormGroup;

  public style;

  hoverInteraction = computed(() => {
    const layers = this.projectLayers();

    if(layers.length == 0)
      return null;

    const hoverInteraction = new Select({
      condition: pointerMove,
      layers: [...layers],
      style:null
    });

      hoverInteraction.on('select', (event) => {
      const selectedFeatures = event.selected;
      if (selectedFeatures.length > 0) {
        const feature = selectedFeatures[0];


        this.hoveredFeature.set(feature.get('featureID'))
      }
      else
      {
        this.hoveredFeature.set(null);
      }

    });
    return hoverInteraction;
  });

  clickInteraction = computed(() => {
    const layers = this.projectLayers();

    if(layers.length == 0)
      return null;

    const clickInteraction = new Select({
      condition: singleClick,
      style:null,
      layers: [...layers]
    });

    clickInteraction.on('select', (event) => {

      event.mapBrowserEvent.stopPropagation();
      event.mapBrowserEvent.preventDefault();

      const selectedFeatures = event.selected;
      if (selectedFeatures.length > 0) {
        const feature = selectedFeatures[0];

        event.mapBrowserEvent.stopPropagation();
        event.mapBrowserEvent.preventDefault();

        const layer = this.featureLayers().layers.find(layer => layer.layerID == feature.get('layerID'));

        if(layer)
        {
          this.featureLayerService.selectLayer(layer);
        }


        this.selectedFeature.set(feature.get('featureID'))

        clickInteraction.getFeatures().clear();
      }
    });
    return clickInteraction;
  });

  featureLayers = toSignal(this.featureLayerService.getLayers());

  overview = toSignal(this.featureLayerService.overview);

  projectLayers = toSignal(this.featureLayerService.layers);

  eskToolActive = toSignal(this.eskToolService.toolActiveChanges());

  editFeatureTargets = toSignal(this.featureLayerService.editTargetFeatures);

  snapLayers = computed(() => [this.propertiesService.propertyBoundariesLayer, ...this.projectLayers()])

  public propertiesLoaded = toSignal(this.propertiesService.propertiesLoaded);


  drawTypeDefinitions: { drawType: Kind; icon: string; layerKind: Kind }[] = [
    { drawType: 'Point', icon: ' fas fa-map-marker-alt', layerKind: 'Point' },
    { drawType: 'Polygon', icon: 'fas fa-draw-polygon', layerKind: 'Polygon' },
    { drawType: 'Circle', icon: 'far fa-circle', layerKind: 'Polygon' },
    { drawType: 'LineString', icon: 'fas fa-minus', layerKind: 'LineString' },
  ];

  highlightedFeature;

  test222 = new FormControl();


  constructor() {

    this.test222.valueChanges.pipe().subscribe(data => {
      this.featureLayerService.selectLayer(data);
    });

    this.featureLayerService.selectedUserFeature.subscribe(feature => {
      const test =  this.featureItems().find(component => {
        return component.elementRef.nativeElement.id == `feature-item-${feature.featureID}`;
      });

      if(test)
      {
        test.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }

    })


    effect(() => {
      const layerEditTarget = this.featureLayerService.currentlySelectedLayer();

      if(layerEditTarget == null)
      {
        return;
      }
      untracked(() => {
        const layer = this.featureLayers().layers.find(lyr => lyr.layerID == layerEditTarget);
        this.test222.setValue(layerEditTarget);
      })
    })

    effect(() => {
      const propertyID = this.propertyID();

      if(propertyID == null)
        return;

      this.propertiesService.setActivePropertyByID(this.propertyID()).subscribe();

    });

    effect(() => {
      const propertiesLoaded = this.propertiesLoaded();
      const propertyID = this.propertyID();

      if(propertiesLoaded && propertyID != null)
      this.mapService.zoomToFeatureEvent.next(propertyID)
    })

    effect(() => {
    const hoverInteraction = this.hoverInteraction();
     const clickInteraction = this.clickInteraction();

     if(hoverInteraction != null)
      this.mapService.getMap().addInteraction(hoverInteraction);

     if(clickInteraction != null)
      this.mapService.getMap().addInteraction(clickInteraction)

    })

    effect(() => {
      const activeTool = this.eskToolActive();
      const hoverInteraction = this.hoverInteraction();
      const clickInteraction = this.clickInteraction();

      if(activeTool)
      {
        if(hoverInteraction != null)
          this.mapService.getMap().removeInteraction(hoverInteraction);

         if(clickInteraction != null)
          this.mapService.getMap().removeInteraction(clickInteraction)
        return;
      }

      if(hoverInteraction != null)
        this.mapService.getMap().addInteraction(hoverInteraction);

       if(clickInteraction != null)
        this.mapService.getMap().addInteraction(clickInteraction)

    })
  }

  ngOnDestroy(): void {

    const hoverInteraction = this.hoverInteraction();
    const clickInteraction = this.clickInteraction();

    if(hoverInteraction != null)
     this.mapService.getMap().removeInteraction(hoverInteraction);

    if(clickInteraction != null)
     this.mapService.getMap().removeInteraction(clickInteraction)

  }

  layerOpenChange(isOpen: boolean, layer: any): void {
    if (!isOpen && this.featureLayerService.currentlySelectedLayer()?.layerID === layer.layerID) {
      this.featureLayerService.selectLayer(null);
    } else if (isOpen) {
      this.featureLayerService.selectLayer(layer);
    }
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(null, [CustomValidators.required]),
    });

  }

  onHover(feature) {

    if(!feature)
      {
        return;
      }

    const highlightStyle = new Style({
      fill:new Fill({color: 'rgba(255,0,0,0.35)'}),
     stroke:new Stroke({color:'rgba(255,0,0,1)', width: 1})
    });


        feature.setStyle(highlightStyle);


        this.featureLayerService.projectLayersGroup.changed();





}



deactivateAllModifyComponents()
{
  const modifyComponents = this.modify();

  modifyComponents.forEach(component => {
    component.active.set(false);
  });
}


onMouseOut(feature) {

  if(!feature)
    {
      return;
    }
    feature.setStyle(null);
    this.featureLayerService.projectLayersGroup.changed();
}

onClick(feature)
{
  if(feature)
    {
      this.mapService.zoomToFeature(feature)
    }
}

  createFeature(feature:Feature, layer)
  {

        this.modalService
          .showCreateFeature(feature,layer)
          .pipe(
            filter((data: createResult) => data.shouldCreate),
            switchMap((data) =>
              this.featureLayerService.createFeature(data.model)
            )
          )
          .subscribe();
  }

  featuresChanged(features: any[])
  {
    features.forEach(feature => {
      const id = feature.get('featureID');

      this.featureLayerService.modifiedGeometries.set(id,feature)
    });

  }

  featureChanged(feature:Feature)
  {
    const id = feature.get('featureID');

    this.featureLayerService.modifiedGeometries.set(id,feature)
  }


  createLayer() {
    this.modalService
      .showCreateLayerDialog()
      .pipe(
        filter((result) => result.create),
        switchMap((result) =>
          this.featureLayerService.createLayer(result.model)
        )
      )
      .subscribe();
  }

  deleteLayer(layer) {
    this.modalService
      .showConfirmDelete('Feature Layer', layer.name)
      .pipe(
        filter((result) => result.ok),
        map(result => result.val),
        switchMap((result) =>
        {
          if(result == false)
            return EMPTY;

        return this.featureLayerService.deleteLayer(layer.layerID)
        }
        )
      )
      .subscribe();
  }

  downloadFile(layerID, srid: number = 4326): void {
    this.modalService
      .showDownloadLayer()
      .pipe(
        switchMap((data) => {
          return this.featureLayerService.downloadGeoJson(layerID, data.srid);
        })
      )
      .subscribe((response) => {
        const blob = new Blob([response.body], {
          type: 'application/geo+json',
        });

        const contentDisposition = response.headers.get('Content-Disposition');

        let filename = 'download.json';
        if (contentDisposition) {
          filename = contentDisposition
            .split(';')[1]
            .split('filename')[1]
            .split('=')[1]
            .trim();
        }

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
  }

  drop(event) {

  }

  onSelectDelete(layer:LayerDTO,featuresToDelete: Array<Feature>)
  {
    const feature = featuresToDelete.length > 0 ? featuresToDelete[0] : null;

    if(feature == null)
      {
        return;
      }

      const featureID = feature.get('featureID')
      const name = feature.get('name');

      const userFeature = layer.features.find(feature => feature.featureID == featureID);


   this.showFeatureLayerDeleteModal(layer.layerID,featureID,name)
      .subscribe(() => {
        this.featureLayerService.modifySource.removeFeature(feature);
        this.featureLayerService.deleteFeature(layer,userFeature).subscribe()

      });
  }

  onDeleteFeature(layer: LayerDTO, feature: UserFeatureDTO, featureName: string) {
    this.showFeatureLayerDeleteModal(layer.layerID, feature.featureID, featureName).subscribe(result => {

      if(result == false)
      {
        return;
      }

      this.featureLayerService.deleteFeature(layer,feature).subscribe()
    });
  }

  showFeatureLayerDeleteModal(layerID,featureID, featureName)
  {
    return this.modalService
    .showConfirmDelete('Feature', featureName)
    .pipe(
      filter(result => result.ok),    map(result => result.val)
    )
  }

  test2(layer:LayerDTO)
  {
    const ref = this.modalService.showComponent<EditProjectLayerComponent>(EditProjectLayerComponent, {});

    ref.componentInstance.projectLayer.set(layer);

    ref.componentInstance.update.subscribe(result => {
      ref.close();

      if(!result.success)
      {
        return;
      }

      this.featureLayerService.updateLayer(layer.layerID, result.model).pipe(switchMap(() => this.featureLayerService.getLayer(layer.layerID))).subscribe((result) => {
        this.featureLayerService.projectLayersGroup.getLayers().remove(layer.mapLayer())
        const targetLayer = this.featureLayerService.setupFeatureLayer(result);
        this.featureLayerService.projectLayersGroup.getLayers().insertAt(0, targetLayer.mapLayer());
      });
    })


  }
}
