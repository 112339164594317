import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  OnInit,
  Renderer2,
  ViewRef,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { SignalRService } from '../../services/signalR.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  EMPTY,
  NEVER,
  combineLatest,
  delay,
  delayWhen,
  filter,
  from,
  fromEvent,
  fromEventPattern,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import OlMap from 'ol/Map';
import { Feature, View } from 'ol';
import { Attribution, ScaleLine } from 'ol/control';
import { transform } from 'ol/proj';
import { BasemapsService } from 'src/app/map/data-access/Basemaps.service';
import LayerGroup from 'ol/layer/Group';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { PropertiesService } from 'src/app/map/features/property/data-access/properties.service';
import { transformExtent } from 'ol/proj';
import { FeatureLayersService } from 'src/app/map/features/property/features/feature-layers/data-access/feature-layers.service';
import { Subject } from '@microsoft/signalr';
import { CdkDrag, CdkDragMove } from '@angular/cdk/drag-drop';
import { PdfMapScaleComponent } from '../ui/pdf-map-scale/pdf-map-scale.component';
import { PdfPropertyDetailsComponent } from '../ui/pdf-property-details/pdf-property-details.component';
import { PdfDraggableComponent } from '../ui/pdf-draggable/pdf-draggable.component';
import { PdfDisclaimerComponent } from '../ui/pdf-disclaimer/pdf-disclaimer.component';
import { PdfEoiComponent } from '../ui/pdf-eoi/pdf-eoi.component';
import { PdfTitleComponent } from '../ui/pdf-title/pdf-title.component';
import { PaperOrientation, PaperSize } from '../models/printDefinition';
import BaseLayer from 'ol/layer/Base';
import TileLayer from 'ol/layer/Tile';
import { basemapDefinitions } from 'src/app/map/util/basemaps';
import { Fill, Stroke, Style } from 'ol/style';
import { SafeHtmlPipe } from 'src/app/shared/pipes/safe-html.pipe';
import { LegendIconComponent } from 'src/app/shared/components/style-renderer/legend-icon/legend-icon.component';
@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CdkDrag,
    PdfMapScaleComponent,
    PdfPropertyDetailsComponent,
    PdfDraggableComponent,
    PdfDisclaimerComponent,
    PdfEoiComponent,
    PdfTitleComponent,
    SafeHtmlPipe,
    LegendIconComponent
  ],
  providers: [SignalRService],
})
export class PdfComponent implements OnInit, AfterViewInit {
  private readonly signalRService = inject(SignalRService);

  private readonly baseMapsService = inject(BasemapsService);

  private readonly projectLayersService = inject(FeatureLayersService);

  private readonly propertiesService = inject(PropertiesService);

  private renderer: Renderer2 = inject(Renderer2);

  private readonly httpClient = inject(HttpClient);

  private readonly route: ActivatedRoute = inject(ActivatedRoute);

  private readonly params = toSignal(this.route.queryParams);

  private readonly initialCenter = [146.6, -30.83];

  private readonly jobID = computed(() => this.params()['job'] ?? null);

  private readonly token = computed(() => this.params()['token']);

  paperOrientation = signal<PaperOrientation>('Portrait');

  private readonly featureMap = new Map<string, Feature>();

  private readonly testSubject = new BehaviorSubject(false);

  paperSize = signal<PaperSize>('A4');

  extent = signal(null);

  elements = signal([]);

  property = signal({});

  mapScale = signal('');


  private bbox: WritableSignal<[number, number, number, number]> = signal([
    144.69933886861926, -41.14617196555231, 145.05514415495557,
    -40.85370704494573,
  ]);

  mapElement = viewChild.required<ElementRef<HTMLElement>>('map');

  sheetElement = viewChild.required<ElementRef<HTMLElement>>('sheet');

  public map = computed(() => {
    if (!this.mapElement()) {
      return new OlMap();
    }

    const map = new OlMap({
      layers: [
        this.propertiesService.propertyGroup,
      ],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: 5,
        maxZoom: 23,
      }),
      target: this.mapElement().nativeElement,
      controls: [
        new Attribution(),
        new ScaleLine({
          bar: true,
          minWidth: 150,
        }),
      ],
    });

    return map
  });



  ab = toObservable(this.map).pipe(filter(map => map != null)).subscribe(map => {
    const mapMoveEnd$ = fromEventPattern(
      handler => map.on('moveend', handler),
      handler => map.un('moveend', handler)
    );
      mapMoveEnd$.pipe(
        startWith(null),
        switchMap(() =>
          of(
            this.calculateMapScale(map)
              .toString()
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
          )
        )
      ).subscribe(data => this.mapScale.set(data))
  });

  bboxEffect = effect(() => {
    if (!this.map() || !this.bbox()) {
      return;
    }

    this.map()
      .getView()
      .fit(transformExtent(this.bbox(), 'EPSG:4326', 'EPSG:3857'));
  });

  projectLayersDefinitions = toSignal(this.projectLayersService.getLayers().pipe(map(projectLayers => projectLayers.layers.map(layer => {return {name: layer.name, style: layer.style}}))));


  private headers = computed(() => {
    if (this.jobID() != null && this.token() != null) {
      return new HttpHeaders({
        Authorization: `Bearer ${this.token()}`,
      });
    }
    return null;
  });

  backgroundLayersLoaded = new BehaviorSubject(false);
  lagunaBayPropertiesLoaded = new BehaviorSubject(false);
  otherPropertiesLoaded = new BehaviorSubject(false);

  readyToPrint = combineLatest([this.backgroundLayersLoaded]).pipe(
    map((values) => values.every((value) => value === true))
  );

  constructor(private injector: Injector) {
    effect(() => {
      const SIZE = this.paperSize();
      const ORIENTATION = this.paperOrientation();

      this.renderer.removeClass(document.body, SIZE == 'A4' ? 'A3' : 'A4');
      this.renderer.removeClass(
        document.body,
        ORIENTATION == 'Portrait' ? 'Landscape' : 'Portrait'
      );

      this.renderer.removeClass(document.body, SIZE);
      this.renderer.removeClass(document.body, ORIENTATION);
      this.renderer.addClass(document.body, SIZE);
      this.renderer.addClass(document.body, ORIENTATION);
    });
    this.propertiesService.propertyLoadedEvent.subscribe((event) => {
      const feature = event.feature;


      const selectedStyle = new Style({
        stroke: new Stroke({
          color: 'rgba(255,0,0,1)',
          width: 1.5,
        }),
        fill: new Fill({ color: 'rgba(255,255,255,0.25)' }),
      });

      feature.setStyle(selectedStyle);
    });

    const jobID = this.route.queryParams.pipe(map(params => params["job"]))
    const token = this.route.queryParams.pipe(map(params => params["token"]))

    combineLatest([jobID, token])
      .pipe(
        filter(([jobID, token]) => !!jobID && !!token)
      ).pipe(
        switchMap(([jobID, token]) => this.signalRService.startConnection(token).pipe(map(() => ([jobID, token])))),
        switchMap(() => this.signalRService.joinRoom(this.jobID())),
        switchMap(() => this.signalRService.getPrintDetails(this.jobID())),
        tap((printDefinition) => {

          this.bbox.set(printDefinition.extent);
          this.setupBaseMaps(printDefinition.selectedBaseMap);
          this.paperOrientation.set(
            printDefinition.paperDefinition.orientation
          );

          this.paperSize.set(printDefinition.paperDefinition.size);
          this.elements.set(printDefinition.elements.map(element => {
            let state = {};
            for (let key in element) {
              if (Object.prototype.hasOwnProperty.call(element, key)) {
                let value;
                  try {
                    value = JSON.parse(element[key]);
                    if (!Array.isArray(value)) {
                      value = element[key];
                    }
                  } catch {
                    value = element[key];
                  }
                state[key] = signal(value);
              }
            }
            return state;
          }));

        })
        ,
        switchMap((printDefinition) =>
        {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${this.token()}`,
          });
          return this.propertiesService.get(printDefinition.propertyID,headers)
          .pipe(map((property) => ({ printDefinition, property })))
        }

        ),
        tap(({ printDefinition, property }) => {
            this.propertiesService.loadPropertyBoundaryFeature(property);
            this.propertiesService.loadPropertyAreaLayer(property);
            this.property.set(property);
        }),
        switchMap(({ printDefinition, property }) => {
          this.projectLayersService.setPropertyID(property.propertyID);
          return this.projectLayersService.getLayersForProperty(this.token())
        }),
        delayWhen(() =>
          this.readyToPrint.pipe(
            switchMap((ready) => (ready ? of('').pipe(delay(1000)) : NEVER))
          )
        )
      )
      .subscribe((data) => {
        this.signalRService.hubConnection.invoke(
          'RenderCompleted',
          this.jobID(),
          this.convertBoundingBoxToCorners(this.bbox()),
          this.getMapBoxInPaper()
        );
      });

  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {}

  async setupBaseMaps(selectedBaseMapName: string) {
    const SELECTED_BASEMAP_DEF = basemapDefinitions.find(
      (definition) => definition.display_name == selectedBaseMapName
    );

    let layers = (await this.baseMapsService.buildLayers([
      SELECTED_BASEMAP_DEF,
    ])) as TileLayer<any>[];

    layers[0].getSource().on('tileloadend', () => {
      this.backgroundLayersLoaded.next(true);
    });

    layers[0].setVisible(true);

    let basemapsGroup = new LayerGroup({
      properties: { title: 'Background Maps' },
      layers: layers,
    });
    this.map().getLayers().insertAt(0, basemapsGroup);
  }

  calculateBboxCenter(
    bbox: [number, number, number, number]
  ): [number, number] {
    const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox;
    const centerLongitude = (minLongitude + maxLongitude) / 2;
    const centerLatitude = (minLatitude + maxLatitude) / 2;
    return [centerLongitude, centerLatitude];
  }

  calculateMapScale(map) {
    const INCHES_PER_UNIT = {
      m: 39.37,
      dd: 4374754,
    };
    const DOTS_PER_INCH = 72;

    if(map == null)
    {
      return 0;
    }

    const unit = map.getView().getProjection().getUnits();
    const resolution = map.getView().getResolution();
    const scale = Math.round(
      INCHES_PER_UNIT[unit] * DOTS_PER_INCH * resolution
    );

    return scale;
  }

  getMapBoxInPaper() {
    const mapWindowHeight = this.mapElement().nativeElement.clientHeight;
    const mapWindowWidth = this.mapElement().nativeElement.clientWidth;
    const borderWidth = 0;

    const mapWindowOffsetLeft = this.mapElement().nativeElement.offsetLeft;
    const mapWindowOffsetTop = this.mapElement().nativeElement.offsetTop;

    const corners = [];

    const mm2inch = 25.4;
    const mm2Points = 72 / 25.4;
    const Points2mm = 25.4 / 72;

    const pageWidth =
      this.sheetElement().nativeElement.clientWidth + borderWidth * 2;
    const pageHeight =
      this.sheetElement().nativeElement.clientHeight + borderWidth * 2;

    // Calculate Page Width and Height Base Upon Page Size and Orientation
    let mapOrientation = this.paperOrientation().toLowerCase();
    let mapSize = this.paperSize().toLowerCase();

    let pageWidthMm = null;
    let pageHeightMm = null;

    // Calculated in mm
    if (mapOrientation == 'portrait' && mapSize == 'a4') {
      pageWidthMm = 210 + borderWidth * Points2mm * 2;
      pageHeightMm = 297 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'landscape' && mapSize == 'a4') {
      pageWidthMm = 297 + borderWidth * Points2mm * 2;
      pageHeightMm = 210 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'portrait' && mapSize == 'a3') {
      pageWidthMm = 297 + borderWidth * Points2mm * 2;
      pageHeightMm = 420 + borderWidth * Points2mm * 2;
    } else if (mapOrientation == 'landscape' && mapSize == 'a3') {
      pageWidthMm = 420 + borderWidth * Points2mm * 2;
      pageHeightMm = 297 + borderWidth * Points2mm * 2;
    }

    // Lower left is 0,0
    // Offset Based Upon the Percentage of the Paper - Calculated in Pixels
    corners[0] = (mapWindowOffsetLeft + borderWidth) / pageWidth; // Left
    corners[1] =
      1 - (mapWindowOffsetTop + borderWidth + mapWindowHeight) / pageHeight; // Bottom
    corners[2] =
      (mapWindowOffsetLeft - borderWidth + mapWindowWidth) / pageWidth; // Right - Remove Border Width
    corners[3] = 1 - (mapWindowOffsetTop + borderWidth) / pageHeight; // Top

    // Printing points for PDF - Convert Percentage of Paper to Pixels
    corners[0] = corners[0] * pageWidthMm * mm2Points;
    corners[1] = corners[1] * pageHeightMm * mm2Points;
    corners[2] = corners[2] * pageWidthMm * mm2Points;
    corners[3] = corners[3] * pageHeightMm * mm2Points;

    return corners;
  }

  convertBoundingBoxToCorners(
    boundingBox: [number, number, number, number]
  ): number[] {
    const [
      minimumLongitude,
      minimumLatitude,
      maximumLongitude,
      maximumLatitude,
    ] = boundingBox;

    // Lower-left corner (minimum longitude, minimum latitude)
    const lowerLeftCorner: [number, number] = [
      minimumLongitude,
      minimumLatitude,
    ];
    // Upper-right corner (maximum longitude, maximum latitude)
    const upperRightCorner: [number, number] = [
      maximumLongitude,
      maximumLatitude,
    ];
    // Upper-left corner (minimum longitude, maximum latitude)
    const upperLeftCorner: [number, number] = [
      minimumLongitude,
      maximumLatitude,
    ];
    // Lower-right corner (maximum longitude, minimum latitude)
    const lowerRightCorner: [number, number] = [
      maximumLongitude,
      minimumLatitude,
    ];

    // Return the coordinates in the specified format
    return [
      lowerLeftCorner[0],
      lowerLeftCorner[1],
      upperRightCorner[0],
      upperRightCorner[1],
      upperLeftCorner[0],
      upperLeftCorner[1],
      lowerRightCorner[0],
      lowerRightCorner[1],
    ];
  }
}
