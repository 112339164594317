import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Injectable,
  inject,
  signal,
  WritableSignal,
  effect,
} from '@angular/core';
import { Collection, Feature } from 'ol';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import {
  BehaviorSubject,
  Subject,
  finalize,
  fromEvent,
  map,
  merge,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import VectorSource from 'ol/source/Vector';
import { Draw, Modify, Translate } from 'ol/interaction';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { DrawEvent } from 'ol/interaction/Draw';
import { ModifyEvent } from 'ol/interaction/Modify';
import OlParser from 'geostyler-openlayers-parser';
import { MapService } from '../../../../../data-access/map.service';
import { BaseEndpointService } from 'src/app/@core/interfaces/IEndpoint';
import { environment } from 'src/environments/environment';
import LayerGroup from 'ol/layer/Group';
import {
  ConvertFeatureToWKT,
  CovertWKTFeature,
} from 'src/app/shared/helpers/transformations';
import { CreateFeatureLayer } from '../ui/create-feature-layer/create-feature-layer.component';
import { TranslateEvent } from 'ol/interaction/Translate';
import { ContextMenuAction } from 'src/app/shared/features/context-menu/context-menu.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Layer } from 'ol/layer';
import { filter } from 'lodash';

export type UserLayerState = {
  layer: VectorLayer<any>;
  visible: boolean;

  features: [
    {
      feature: Feature<Geometry>;
      isEditing: boolean;
    }
  ];
};

export type LayerState = {
  layer: VectorLayer<any>;
  visible: boolean;
  isEditing: boolean;
  expanded: boolean;
  selected: boolean;
  displayName: string;
  opacity: number;
};

export interface ProjectLayersDTO {
  layers: LayerDTO[];
  propertyArea: number;
  cumulativeArea: number;
  percentageOfProperty: number;
  balance: number;
}

export type FeatureState = {
  isEditing: boolean;
  feature: Feature<any>;
};

export interface LayerDTO {
  layerID: number;
  name: string;
  style?: any;
  features: UserFeatureDTO[];
  state?: WritableSignal<LayerState>;
  isEditing?: WritableSignal<boolean>;
  mapLayer?: WritableSignal<VectorLayer<any>>
}

export interface UserFeatureDTO {
  featureID: number;
  name: string;
  geom: string;
  attributes: FeatureAttributes[];
  isEditing?: WritableSignal<boolean>;
  olFeature?: Feature<any>;
}

export interface FeatureAttributes {
  [key: string]: any;
}

export interface PropertyOverview {
  propertyID: number;
  propertyArea: number;
  cumulativeArea: number;
  percentageOfProperty: number;
  balance: number;
}


const editStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)', // Semi-transparent white fill
  }),
  stroke: new Stroke({
    color: '#ffcc33', // Orange stroke color
    width: 2,
  }),
  image: new Circle({
    radius: 7,
    fill: new Fill({
      color: '#ffcc33', // Orange fill for circle
    }),
  }),
});

@Injectable({
  providedIn: 'root',
})
export class FeatureLayersService extends BaseEndpointService {
  public route: ActivatedRoute = inject(ActivatedRoute);
  public router: Router = inject(Router);

  public layerSate = new Map<number, WritableSignal<LayerState>>();
  public featureState = new Map<number, WritableSignal<FeatureState>>();
  public layers = new BehaviorSubject([]);

  public projectLayersGroup = new LayerGroup({
    properties: { title: 'Project Layers' },
  });

  private readonly userLayers = new BehaviorSubject<ProjectLayersDTO>({
    layers: [],
    propertyArea: 0,
    cumulativeArea: 0,
    percentageOfProperty: 0,
    balance: 0,
  });

  overview = this.userLayers.asObservable().pipe(
    switchMap(() => this.getOverview())
  );

  public readonly editTargetFeatures = new BehaviorSubject<Feature[]>([]);

  readonly somethingChanged = new Subject();

  public readonly modifySource = new VectorSource();
  public readonly modifyLayer = new VectorLayer({
    source: this.modifySource,
    style: editStyle,
  });
  private readonly modifyInteraction = new Modify({
    source: this.modifySource,
  });

  public readonly modifiedGeometries = new Map<number, Feature<any>>();
  public currentlySelectedLayer = signal(null);
  private parser = new OlParser();

  public propertyID;
  public currentlyEditingLayer = signal<LayerDTO>(null);
  public currentEditTarget = signal(null);


  public selectedUserFeature = new Subject<UserFeatureDTO>();

   addModifyFeature$ = fromEvent(this.modifySource, 'addfeature');
   removeModifyFeature$ = fromEvent(this.modifySource, 'removefeature');


  constructor() {
    super({ route: 'properties/%PROPERTYID%/layers' });

    merge(this.addModifyFeature$, this.removeModifyFeature$)
    .pipe(
      map(() => this.modifySource.getFeatures())
    )
    .subscribe(features => {
      this.editTargetFeatures.next(features);
    });

  }

  setPropertyID(propertyID) {
    if (this.propertyID != null) {
      this.setResourceID(this.propertyID, propertyID);
    } else {
      this.setResourceID('%PROPERTYID%', propertyID);
    }
    this.propertyID = propertyID;
  }

  getOverview()
  {
    return this.propertyID == null ? of({
      propertyID: 0,
      propertyArea: 0,
      cumulativeArea: 0,
      percentageOfProperty: 0,
      balance: 0})
     : this.http.get<PropertyOverview>(`${this.endpoint}/overview`)
  }

  getCurrentlySelectedLayerState() {
    return this.layerSate.get(this.currentlySelectedLayer()?.layerID);
  }

  public getLayersForProperty(accessToken?: string) {
    let headers = new HttpHeaders();
    if (accessToken) {
      headers = new HttpHeaders({
        Authorization: `Bearer ${accessToken}`,
      });
    }

    this.projectLayersGroup.getLayers().clear();
    this.projectLayersGroup.getLayers().push(this.modifyLayer);

    return this.http
      .get<ProjectLayersDTO>(this.endpoint, { headers: headers })
      .pipe(
        map((projectLayersData) => {
          projectLayersData.layers.map((layer) => this.setupFeatureLayer(layer));

          return projectLayersData;
        }),
        shareReplay(1),
        tap((data) => {
          data.layers.forEach((layer) => {

          this.projectLayersGroup.getLayers().insertAt(0, layer.mapLayer());
          this.layers.next(this.projectLayersGroup.getLayers().getArray());
          this.projectLayersGroup.changed();
          this.userLayers.next(data);
        })
      }
      ));
  }

  loadLayers() {
    this.getLayersForProperty().subscribe();
  }

  getLayers() {
    return this.userLayers.asObservable();
  }

  editFeature(userFeature: UserFeatureDTO) {
    const currentLayerEditTarget = this.currentlyEditingLayer();
    const currentFeatureEditTarget = this.currentEditTarget();

    if (currentLayerEditTarget != null) {
      this.cancelLayerEdit(currentLayerEditTarget);
    }

    if (currentFeatureEditTarget != null) {
      this.cancelFeatureEdit(currentFeatureEditTarget);
    }

    const feature = userFeature.olFeature;

    const modFeature = feature.clone();
    modFeature.setStyle(editStyle);
    this.currentEditTarget.set(modFeature);

    this.modifySource.clear();
    this.modifySource.addFeature(modFeature);

    this.modifyLayer.setVisible(true);

    this.currentEditTarget.set(userFeature);
    userFeature.isEditing.set(true);
  }

  cancelFeatureEdit(userFeature:UserFeatureDTO) {
    this.modifyLayer.setVisible(false);
    this.modifiedGeometries.clear();
    userFeature?.isEditing?.set(false);
    this.currentEditTarget.set(null);
  }

  saveFeatureChanges(layer:LayerDTO, userFeature: UserFeatureDTO) {
    const modifiedFeature = this.modifiedGeometries.get(userFeature.featureID);

    const wkt = ConvertFeatureToWKT(modifiedFeature);

    this.http
    .patch(`${this.endpoint}/${layer.layerID}/features/${userFeature.featureID}`, {
      featureID: userFeature.featureID,
      wkt: wkt,
    })
    .pipe(
      switchMap(() => this.updateLayerFeatures(layer.layerID) ),
      finalize(() => {
        this.cancelFeatureEdit(userFeature);
      })
    )
    .subscribe({
      next: (result) => {

      },
      error: (err) => {
        console.error('Error updating feature:', err);
      },
    });

    this.cancelFeatureEdit(userFeature);
  }

  deleteFeature(layer:LayerDTO,feature:UserFeatureDTO) {
    return this.http
      .delete(`${this.endpoint}/${layer.layerID}/features/${feature.featureID}`)
      .pipe(switchMap(() => this.updateLayerFeatures(layer.layerID)),tap( () => {
        this.cancelFeatureEdit(feature);
      }))
  }

  editLayer(layer: LayerDTO) {
    const currentLayerEditTarget = this.currentlyEditingLayer();
    const currentFeatureEditTarget = this.currentEditTarget();

    if (
      currentLayerEditTarget != null &&
      currentLayerEditTarget.layerID != layer.layerID
    ) {
      this.cancelLayerEdit(currentLayerEditTarget);
    }

    if (currentFeatureEditTarget != null) {
      this.cancelFeatureEdit(currentFeatureEditTarget);
    }

    layer.isEditing.set(true);

    const features = layer.mapLayer().getSource().getFeatures();

    const clonedFeatures = features.map((feature) => feature.clone());

    this.modifySource.clear();
    this.modifySource.addFeatures(clonedFeatures);
    this.modifyLayer.setVisible(true);

    this.currentlyEditingLayer.set(layer);
    this.selectLayer(layer);
  }

  cancelLayerEdit(layer: LayerDTO) {
    this.modifyLayer.setVisible(false);
    this.modifiedGeometries.clear();
    layer.isEditing?.set(false);
    this.currentlyEditingLayer.set(null);
  }

  selectLayer(layer) {
    const currentLayer = this.currentlySelectedLayer();

    if (layer == null && currentLayer == null) {
      return;
    } else if (layer == null && currentLayer != null) {
      this.currentlySelectedLayer.set(null);
      return;
    }

    this.currentlySelectedLayer.set(layer);
  }

  saveLayerChanges(layer:LayerDTO) {
    let updateModels = [];

    this.modifiedGeometries.forEach((feature, id) => {
      let wkt = ConvertFeatureToWKT(feature);
      updateModels.push({ featureID: id, wkt: wkt });
    });

    if (updateModels.length > 0) {
      this.http
        .patch(`${this.endpoint}/${layer.layerID}/features`, {
          models: updateModels,
        })
        .pipe(switchMap(() => this.updateLayerFeatures(layer.layerID))).subscribe()

    }

    this.cancelLayerEdit(layer);
  }

  getLayer(layerID)
  {
    return this.http.get<LayerDTO>(`${this.endpoint}/${layerID}`)
  }

  createFeature(model) {
    return this.http
      .post(`${this.endpoint}/${this.currentlySelectedLayer().layerID}`, model).pipe(switchMap(() => this.updateLayerFeatures(this.currentlySelectedLayer().layerID)))
  }


  createLayer(model: CreateFeatureLayer) {
    return this.http.post<number>(this.endpoint, model).pipe(switchMap((layerID) => this.updateLayerFeatures(layerID)))
  }

  updateLayer(layerID: number, model: any) {
    return this.http.patch(`${this.endpoint}/${layerID}`, { ...model }).pipe(switchMap(() => this.updateLayerFeatures(layerID)))
  }

  deleteLayer(layerID: number) {
    return this.http.delete(`${this.endpoint}/${layerID}`).pipe(tap(() => {
      const targetLayer = this.userLayers.value.layers.find(layer => layer.layerID === layerID) ?? null;

      if(targetLayer == null)
        return;


      const existingLayers = this.userLayers.value.layers.filter(layer => layer.layerID !== layerID);


      this.projectLayersGroup.getLayers().remove(targetLayer.mapLayer());

      this.userLayers.next({
        ...this.userLayers.value,
        layers: [...existingLayers],
      });
    }),finalize(() => this.currentlySelectedLayer.set(null)))
  }

  downloadGeoJson(layerID: number, srid: number) {
    return this.http.get(
      `${environment.apiUrl}/properties/${this.propertyID}/layers/${layerID}/geojson?srid=${srid}`,
      { responseType: 'blob', observe: 'response' }
    );
  }

  private updateLayerFeatures(layerID: number) {
    return this.getLayer(layerID).pipe(
      switchMap((layer) => of(this.userLayers.value).pipe(
        map((projectLayers) => ({
          currentLayers: projectLayers.layers,
          layer,
        }))
      )),
      tap((result) => {

        let targetLayer = result.currentLayers.find(layer => layer.layerID === result.layer.layerID) ?? null;
        const existingLayers = result.currentLayers.filter(layer => layer.layerID !== result.layer.layerID);


        if (targetLayer == null) {
          targetLayer = this.setupFeatureLayer(result.layer);
          this.projectLayersGroup.getLayers().insertAt(0, targetLayer.mapLayer());
        } else {

          targetLayer = Object.assign(targetLayer, result.layer);
          targetLayer = this.setupLayerFeatures(targetLayer);
        }
        const layers = [...existingLayers, targetLayer];


        this.userLayers.next({
          ...this.userLayers.value,
          layers: layers.sort((a, b) => a.layerID - b.layerID)
        });
      })
    );
  }

  private setupLayerFeatures(layer: LayerDTO)
  {
    const source = layer.mapLayer().getSource();
    source.clear();


     layer.features.map((feature) => {
      const olFeature = CovertWKTFeature(feature.geom);

      // Set properties for OpenLayers feature
      olFeature.setProperties({
        name: feature.name,
        ...feature.attributes,
        featureID: feature.featureID,
        layerID: layer.layerID,
      });

      source.addFeature(olFeature);



      feature.olFeature = olFeature;
      feature.isEditing = signal(false);

      return feature;
    });

    return layer;
  }

   setupFeatureLayer(layer:LayerDTO)
  {
    const vs = new VectorSource();
    const vl = new VectorLayer({
      properties: {
        ...layer,
        contextActions: <ContextMenuAction[]>[
          {
            label: 'Edit',
            action: (olFeature) => {
              const layerWithFeature =
                this.userLayers.value.layers.find((lyr) =>
                  lyr.features.some(
                    (feature) =>
                      feature.featureID === olFeature.get('featureID')
                  )
                );

              if (layerWithFeature) {
                const featureDTO = layerWithFeature.features.find(
                  (feature) =>
                    feature.featureID === olFeature.get('featureID')
                );

                if (!this.router.url.endsWith('/digitize')) {
                  this.router
                    .navigate([
                      'map',
                      'property',
                      this.propertyID,
                      'digitize',
                    ])
                    .then(() => {
                      this.editFeature(featureDTO);
                      this.currentlySelectedLayer.set(layerWithFeature)
                      this.selectedUserFeature.next(featureDTO);
                    });
                } else {
                  this.editFeature(featureDTO);
                  this.currentlySelectedLayer.set(layerWithFeature)
                  this.selectedUserFeature.next(featureDTO);
                }
              } else {
                console.error('Feature not found');
              }
            },
          },
          {
            label: 'Edit Layer',
            action: () => {
              if (!this.router.url.endsWith('/digitize')) {
                this.router
                  .navigate([
                    'map',
                    'property',
                    this.propertyID,
                    'digitize',
                  ])
                  .then(() => {
                    this.editLayer(layer);
                  });
              } else {
                this.editLayer(layer);
              }
            },
          },
        ],
      },
      source: vs,
      visible: true,
    });

    vl.set('title', layer.name);

    if (layer.style) {
      this.parser.writeStyle(layer.style).then((style) => {
        vl.setStyle(style.output);
      });
    }

    layer.isEditing = signal(false);
    layer.mapLayer = signal(vl);

    layer = this.setupLayerFeatures(layer);

    return layer;
  }


}
