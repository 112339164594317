<div class="d-flex create-property-group">
  <button class="button button--blue" (click)="showToolbar('digitize')">
    <span class="fa fa-pencil button-icon"></span>
    Digitize Property Boundary
  </button>
  <button class="button button--blue" (click)="showToolbar('cadastre')">
    <span class="fa fa-location-dot button-icon"></span>
    Create From Cadastre
  </button>
</div>

<app-toolbar *ngIf="activeToolbar() === 'digitize'">
  <digitize-property-overlay (createEvent)="handleCreateEvent($event)"></digitize-property-overlay>
  <i
    appTooltip="Digitize the polygon/s, once done click the (tick)"
    toolTipPosition="right"
    [triggerOnClick]="true"
    class="fa fa-info-circle">
  </i>
</app-toolbar>


<app-toolbar *ngIf="activeToolbar() === 'cadastre'">
  <select-property-cadastre-overlay (createEvent)="handleCreateEvent($event)"></select-property-cadastre-overlay>
  <i
    appTooltip="Create a property boundary from cadastre, once done click the (tick)"
    toolTipPosition="right"
    [triggerOnClick]="true"
    class="fa fa-info-circle">
  </i>
</app-toolbar>
